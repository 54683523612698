import { Location } from "../Contexts/State/Types";
import { NewLocationForm } from "../Pages/Home/Report/Side/Main";

interface LocationResponse {
    address1: string;
    address2: string;
    city: string;
    locationId: number;
    nickName: string;
    policyNumber: string;
    state: string;
    zipCode: string;
}

export async function getByPolicyNumber(policyNumber: string): Promise<LocationResponse[] | null> {
    try {
        const res = await fetch(`api/Location/GetByPolicyNumber?policyNumber=${policyNumber}`);
        const data = await res.json();
        return data;
    } catch (e) {
        console.error(e);
    }

    return null;
}

interface Add {
    form: NewLocationForm;
    policyNumber: string;
}

export async function add({ form, policyNumber }: Add): Promise<LocationResponse | null> {
    try {
        const res = await fetch("api/location/add", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ ...form, policyNumber })
        });

        const data = await res.json();
        return data;
    } catch (e) {
        console.error(e);
    }

    return null;
}


interface Edit {
    policyNumber: string;
    submitter: string;
    form: NewLocationForm;
    locationId: number;
    dateEdited: string;
}

export async function edit({ policyNumber, submitter, form, locationId, dateEdited }: Edit): Promise<LocationResponse | null> {
    const location: Location = { ...form, locationId };
    console.log(JSON.stringify({ policyNumber, submitter, location, dateEdited }));
    try {
        const res = await fetch("api/location/update", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ policyNumber, submitter, location, dateEdited })
        });

        const data = await res.json();
        return data;
    } catch (e) {
        console.error(e);
    }

    return null;
}

interface Remove {
    policyNumber: string;
    submitter: string;
    locationId: number;
    dateDeleted: string;
}

export async function remove({ policyNumber, submitter, locationId, dateDeleted }: Remove): Promise<boolean | null> {
    console.log(JSON.stringify({ policyNumber, submitter, locationId, dateDeleted }));
    try {
        const res = await fetch(`api/location/delete`, {
            method: "DELETE",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ policyNumber, submitter, locationId, dateDeleted })
        });

        const data = await res.json();
        return data;
    } catch (e) {
        console.error(e);
    }

    return null;
}