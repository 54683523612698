export interface MessageResponse {
    page: string;
    section: string;
    city: string;
    prompt: string;
    toolTip: string;
}

export async function getByPage(): Promise<MessageResponse[] | null> {
    try {
        const res = await fetch(`api/Message/GetAll`);
        const data = await res.json();
        return data;
    } catch (e) {
        console.error(e);
    }

    return null;
}
