import React from 'react';
import { useWindowSize } from '../../../../Hooks/UseWindowSize';
import { InstructionHorizontal, InstructionVertical } from './Instruction';

const instructions = [
    "Enter employee information for all positive case(s) prior to 1/1/24, that you are entering",
    "If address is not in 'All Work Locations' list, select 'Add New' location prior to or during case entry",
    "Remember to enter the date employee last worked prior to positive test date and highest employee count for each location where they worked in the date range displayed",
    "Remember to save each employee case. You can enter multiple employee cases in a single session",
    "Once you have saved your case(s), press 'Submit and Download Report' to see your reporting results"
]

export default function ReportTop() {
    const { width } = useWindowSize();

    return width >= 992
        ?
        <div className="row mt-2">
            {instructions.map((text, index) =>
                <div key={text} className="col row align-items-start">
                    <InstructionVertical index={index} text={text} />
                    {index < instructions.length - 1 ?
                        <i className="fas fa-long-arrow-alt-right fa-3x text-secondary mx-3 align-self-center"></i>
                        : null
                    }
                </div>
            )}
        </div>
        :
        <div className="column mt-2">
            {instructions.map((text, index) =>
                <InstructionHorizontal key={text} index={index} text={text} />
            )}
        </div>
}
