import React from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import { addOrRemoveNewCaseFormLocations, clearLocationsDropdown, clickSelectLocationForm, editNewCaseForm } from '../../../../Actions/Employee';
import { useSetStateContext, useStateContext } from '../../../../Contexts/State/Context';
import { NewCaseFormLocation } from '../../../../Contexts/State/Types';
import { FieldValidationMessage, RedAsterisk } from '../../../../Utils/Components';
import { dateToMMDDYYYY, dateToYYYYMMDD, locationFormat, stringToMMDDYYYY } from '../../../../Utils/Utils';
import MessageToolTips from '../../MessageToolTips';
import { Validations } from '../Validations';
import { Display } from './NewCaseFormContainer';

interface Props {
    fieldErrors: Validations;
    fieldWarnings: Validations;
    hasSubmitted: boolean;
    setHasSubmitted: React.Dispatch<React.SetStateAction<boolean>>;
    display: Display;
    setDisplay: React.Dispatch<React.SetStateAction<Display>>;
    selectedLocations: NewCaseFormLocation[];
    setSelectedLocations: React.Dispatch<React.SetStateAction<NewCaseFormLocation[]>>;
}

export default function NewCaseForm({ fieldErrors, fieldWarnings, hasSubmitted, setHasSubmitted, display, setDisplay, selectedLocations, setSelectedLocations }: Props) {
    // Context
    const state = useStateContext();
    const { locations, newCaseForm: { employeeNumber, positiveTestDate, awarePositiveDate }, newCaseFormLocations } = state;
    const setState = useSetStateContext();

    // Events
    const onSelect = () => clickSelectLocationForm({ setDisplay });
    const handleCancelDropdown = () => clearLocationsDropdown({ setSelectedLocations, setDisplay });
    const handleLocationCheck = (e: React.ChangeEvent<HTMLInputElement>) => addOrRemoveNewCaseFormLocations({ e, locations, selectedLocations, setSelectedLocations });

    const handleFormUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
        setHasSubmitted(false);
        editNewCaseForm({ e, state, setState });
    }

    const handleFormUpdateForDate = (e: React.SyntheticEvent<any, Event> | undefined, date: Date | [Date, Date] | null, name: string) => {
        (e as React.ChangeEvent<HTMLInputElement>).target.name = name;
        if (date instanceof Date)
            (e as React.ChangeEvent<HTMLInputElement>).target.value = dateToYYYYMMDD({ date, separator: "-" });
        handleFormUpdate(e as React.ChangeEvent<HTMLInputElement>);
    }

    const handleAddSelectedLocations = () => {
        setState(state => ({ ...state, newCaseFormLocations: [...newCaseFormLocations, ...selectedLocations] }));
        setSelectedLocations([]);
        setDisplay(display => ({ ...display, showAllWorkLocations: false, showReportNewCasesLocations: true }));
    }

    // Variables
    const dateMin = new Date("07/06/2020");
    const dateMax = new Date(dateToMMDDYYYY({ separator: "/" }));
    const datePositiveMax = new Date("12/31/2023");
    const formatPositive = positiveTestDate === "" ? null : new Date(stringToMMDDYYYY({ date: positiveTestDate, separator: "/" }));
    const formatAware = awarePositiveDate === "" ? null : new Date(stringToMMDDYYYY({ date: awarePositiveDate, separator: "/" }));

    return display.showReportNewCase
        ?
        <>
            <Form.Group as={Col} xs={12} lg={6} xl={4} controlId={"employeeNumber"} className="pr-lg-2 py-2">
                <Form.Label>Employee ID<RedAsterisk /></Form.Label>
                <MessageToolTips prompt='Employee Number' helpText='' />
                <Form.Control className="p-1" type="text" name="employeeNumber" onChange={handleFormUpdate} value={employeeNumber} />
                <FieldValidationMessage display={fieldWarnings.employeeNumber} message="Please do not use social security numbers" type="warning" />
                <FieldValidationMessage display={hasSubmitted && fieldErrors.employeeNumber} message="Field cannot be empty" type="danger" />
            </Form.Group>
            <Form.Group as={Col} xs={12} lg={6} xl={4} className="pl-lg-2 pr-xl-2 py-2">
                <Form.Label>Positive Test Date before 2024<RedAsterisk /></Form.Label>
                <MessageToolTips prompt='Positive Test Date' helpText='' />
                <DatePicker className="p-1 form-control" id="positiveTestDate" selected={formatPositive} onChange={(date, e) => handleFormUpdateForDate(e, date, "positiveTestDate")} minDate={dateMin} maxDate={datePositiveMax} placeholderText="mm/dd/yyyy" />
                <FieldValidationMessage display={hasSubmitted && fieldErrors.positiveTestDate} message="Please select a date" type="danger" />
            </Form.Group>
            <Form.Group as={Col} xs={12} lg={6} xl={4} className="pl-xl-2 py-2">
                <Form.Label>Employer Notified On<RedAsterisk /></Form.Label>
                <MessageToolTips prompt='Aware Positive Date' helpText='' />
                <DatePicker className="p-1 form-control w-100" id="awarePositiveDate" selected={formatAware} onChange={(date, e) => handleFormUpdateForDate(e, date, "awarePositiveDate")} minDate={dateMin} maxDate={dateMax} placeholderText="mm/dd/yyyy" />
                <FieldValidationMessage display={hasSubmitted && fieldErrors.awarePositiveDate} message="Must be on or after positive test date" type="danger" />
            </Form.Group>
            <Form.Group as={Col} xs={12} controlId={"location"} className="py-2">
                <Form.Label>All Work Locations Visited by Employee (14 days prior to [PositiveTestDate])<RedAsterisk /></Form.Label>
                <MessageToolTips prompt='All Work Locations Visited by Employeer' helpText='' />
                <Form.Control as="select" className="rounded-top" name="location" onClick={onSelect} onMouseDown={(e: React.MouseEvent<HTMLSelectElement>) => e.preventDefault()}>
                    <option>Select all that apply</option>
                </Form.Control>
                {display.showAllWorkLocations &&
                    <div className="border border-top-0 rounded-bottom px-3 py-1" style={locations.length > 6 ? { maxHeight: "250px", overflowY: "scroll" } : undefined}>
                        {locations.filter(location => newCaseFormLocations.every(x => location.locationId !== x.locationId)).map((location) =>
                            <Form.Group as={Row} md={12} key={location.locationId} controlId={location.locationId.toString()} className="py-2 align-items-center">
                                <Form.Label className="w-100">
                                    <Form.Check inline className="mr-2" type="checkbox" name={location.locationId.toString()} onChange={handleLocationCheck} />
                                    <span>{locationFormat(location)}</span>
                                </Form.Label>
                            </Form.Group>
                        )}
                    </div>
                }
                {display.showAllWorkLocations &&
                    <Form.Group as={Row} md={12} className="mb-2 mt-4">
                        <Col xs={6}>
                            <Button type="button" className="btn btn-secondary mr-2 border rounded w-100" onClick={handleCancelDropdown}>Cancel</Button>
                        </Col>
                        <Col xs={6}>
                            <Button type="button" className="btn btn-primary ml-2 border rounded w-100" onClick={handleAddSelectedLocations} disabled={selectedLocations.length === 0}>Apply</Button>
                        </Col>
                    </Form.Group>
                }
            </Form.Group>
        </>
        :
        null;
}
