import { QuestionnaireForm } from "../Pages/Home/Questionnaire/Content/Main";

interface AddResponse {
    reportId: number;
    created: string;
    policyNumber: string;
    submissionDate: string;
    submitter: string;
    submitterEmail: string;
    submitterPhone: string;
    employeeOnsiteAfterJulySixth: boolean;
    employeeOnsiteAfterPositiveDiagnosis: boolean;
    isWorkRelated: boolean;
    hasTestedPositive: boolean;
    isLocationClosed: boolean;
    isOutbreak: boolean;
    reportingPeriodId: number;
}

export async function commandAddReport(form: QuestionnaireForm): Promise<AddResponse | null> {
    const date = new Date().toISOString();

    try {
        const body = JSON.stringify({
            SubmissionDate: date.trim(),
            PolicyNumber: form?.policyNumber?.trim(),
            IsWorkRelated: form.isWorkRelated,
            EmployeeOnsiteAfterJulySixth: form.isOnSite,
            HasTestedPositive: form.isPositive,
            EmployeeOnsiteAfterPositiveDiagnosis: form.isOnSite14DaysPrior,
            Submitter: form?.firstName?.trim() + " " + form?.lastName?.trim(),
            SubmitterEmail: form?.email?.trim(),
            SubmitterPhone: form?.phone?.replace(/( |-|\(|\))/g, "").replace(/-/g, "").trim(),
        });

        const res = await fetch("api/report/add", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body
        });

        const data = await res.json();
        return data;
    } catch (e) {
        console.error(e);
    }

    return null;
}

export async function queryIsValidPolicyNumber(policyNumber: string): Promise<boolean | null> {
    try {
        const res = await fetch(`api/Report/isPolicyNumberValid?policyNumber=${policyNumber}`);
        const data = await res.json();
        return data;
    } catch (e) {
        console.error(e);
    }

    return null;
}

export async function queryPreviousPolicyNumber(policyNumber: string): Promise<string | null> {
    try {
        const res = await fetch(`api/Report/getPreviousPolicyNumber?policyNumber=${policyNumber}`);
        const data = await res.json();
        return data;
    } catch (e) {
        console.error(e);
    }

    return null;
}

export async function queryInsuredName(policyNumber: string): Promise<string | null> {
    try {
        const res = await fetch(`api/Report/getInsuredName?policyNumber=${policyNumber}`);
        const data = await res.json();
        return data;
    } catch (e) {
        console.error(e);
    }

    return null;
}
