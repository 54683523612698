import React, { useEffect, useRef } from 'react';
import { useSetStateContext, useStateContext } from '../../../../Contexts/State/Context';
import { Update, useForm } from '../../../../Hooks/UseForm';
import { clearPolicyAndInsured } from '../Actions';
import { onSubmitQuestionnaireForm } from '../Events';
import { validateQuestionnairForm } from '../Validations';
import ReportForm from "./ReportForm";
import ReportQuestions from "./ReportQuestions";

// initial data for form hook
export interface QuestionnaireForm {
    isWorkRelated: boolean | null,
    isOnSite: boolean | null,
    isPositive: boolean | null,
    isOnSite14DaysPrior: boolean | null,
    policyNumber: string,
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
}

const initialData: QuestionnaireForm = {
    isWorkRelated: null,
    isOnSite: null,
    isPositive: null,
    isOnSite14DaysPrior: null,
    policyNumber: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
}

function modifier({ type, name, value, form }: Update) {
    switch (name) {
        case "isWorkRelated":
            form.isOnSite = null;
            form.isPositive = null;
            form.isOnSite14DaysPrior = null;
            break;
        case "isOnSite":
            form.isPositive = null;
            form.isOnSite14DaysPrior = null;
            break;
        case "isPositive":
            form.isOnSite14DaysPrior = null;
            break;
        default:
            break;
    }
    return form;
}

export default function QuestionnaireContent() {
    // get parameters from state context
    const state = useStateContext();
    const { displayQuestionnaireForm, policyNumber, insuredName } = state;
    const setState = useSetStateContext();
    const ref = useRef<string>("");

    // create hook - manages internal form state by itself and exposes properties
    // onFormEvent needs to be passed to each input for onChange property
    // when the hook re-renders, so does this component
    const { form, onFormEvent, resetForm, lastInput, hasSubmitted, setHasSubmitted } = useForm({ initialData, modifier });
    const { fieldErrors, isValid } = validateQuestionnairForm({ form });
    ref.current = lastInput.name;

    // called after every form update
    useEffect(() => {
        const { name, value } = lastInput;

        // update state if name exists in state as parameter
        if (name !== null && name in state) {
            setState(state => {
                (state as Record<string, any>)[name] = value;
                return state;
            });
        }

        // only call this useEffect if the lastInput updates or state
    }, [lastInput, setState, state])

    useEffect(() => {
        fieldErrors.policyNumber && clearPolicyAndInsured({ setState });
    }, [fieldErrors.policyNumber, setState])

    const canSubmit = isValid && policyNumber !== "" && insuredName !== "";

    return (
        <div>
            <form className="row mt-4" onSubmit={e => onSubmitQuestionnaireForm({ e, form, isValid: canSubmit, setState, setHasSubmitted })}>
                {displayQuestionnaireForm
                    ?
                    <ReportForm form={form} onFormEvent={onFormEvent} resetForm={resetForm} fieldErrors={fieldErrors} hasSubmitted={hasSubmitted} />
                    :
                    <ReportQuestions key={form.toString()} form={form} onFormEvent={onFormEvent} />
                }
            </form>
        </div>
    );
}
