import React from 'react';
import { Route } from 'react-router';

import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import StateProvider from "./Contexts/State/Context";

import Layout from './Pages/Layout/Main';
import Home from './Pages/Home/Home';
import Contact from './Pages/Contact/Main';
import Acknowledgement from './Pages/Acknowledgement/Main';

import "./App.css"

export default function App() {
    return (
        <StateProvider>
            <Layout>
                <Route exact path='/'>
                    <Home />
                </Route>
                <Route exact path='/contact'>
                    <Contact />
                </Route>
                <Route exact path='/acknowledgement'>
                    <Acknowledgement />
                </Route>
            </Layout>
        </StateProvider>
    );
};
