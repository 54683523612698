import React from "react";
import { State } from "../../../Contexts/State/Types";
import { commandAddReport, queryInsuredName, queryIsValidPolicyNumber } from "../../../Data/Report";
import { dateToMMDDYYYY, fullnameFormat } from "../../../Utils/Utils";
import { QuestionnaireForm } from "./Content/Main";

// Called when clicking "next steps" in the questionnaire questions section
interface ContinueToForm {
    setState: React.Dispatch<React.SetStateAction<State>>;
}

export function ContinueToForm({ setState }: ContinueToForm) {
    setState(state => ({ ...state, displayQuestionnaireForm: true }))
}

// Called when clicking "Go back" in the questionnaire form section
interface BackToQuestionnaire {
    setState: React.Dispatch<React.SetStateAction<State>>;
}

export function backToQuestionnaire({ setState }: BackToQuestionnaire) {
    setState(state => ({ ...state, displayQuestionnaireForm: false }))
}


// Policy number input, validates policy number and gets insured name if valid
interface ValidatePolicyGetInsured {
    setPolicyExists: React.Dispatch<React.SetStateAction<boolean>>;
    setState: React.Dispatch<React.SetStateAction<State>>;
    policyNumber: string;
}

export async function validatePolicyGetInsured({ setPolicyExists, setState, policyNumber }: ValidatePolicyGetInsured) {
    const policyExists = await queryIsValidPolicyNumber(policyNumber);
    setPolicyExists(policyExists || false);

    if (policyExists) {
        const insuredName = await queryInsuredName(policyNumber);
        insuredName &&
            setState(state => ({ ...state, insuredName }));
    } else if (policyNumber !== "") {
        setState(state => ({ ...state, policyNumber: "", insuredName: "" }));
    }
}

// Reset policy number and insured name
interface ClearPolicyAndInsured {
    setState: React.Dispatch<React.SetStateAction<State>>;
}

export async function clearPolicyAndInsured({ setState }: ClearPolicyAndInsured) {
    setState(state => ({
        ...state,
        policyNumber: "",
        insuredName: ""
    }))
}


// Submits questionnaire form (completion of both questionnaire questions and actual form)
interface HandleFormSubmit {
    form: QuestionnaireForm;
    setState: React.Dispatch<React.SetStateAction<State>>;
}

export async function addReport({ form, setState }: HandleFormSubmit) {
    setState(state => ({ ...state, displayLoadingModal: true }))
    const data = await commandAddReport(form);
    if (!data) return;

    const date = dateToMMDDYYYY({ date: new Date(data.created), separator: "/" });
    setState(state => ({
        ...state,
        reportId: data.reportId,
        reportCreatedAt: date,
        displayReporting: true,
        displayLoadingModal: false,
        submitter: fullnameFormat(form.firstName, form.lastName)
    }));
};
