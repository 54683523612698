import { State } from "../../../Contexts/State/Types";
import { compareDateStrings } from "../../../Utils/Utils";
import { EditCaseForm } from "./Content/Main";
import { NewLocationForm } from "./Side/Main";

// General form of a validation
export type Validations = Record<string, boolean>

// Validation for add new location
interface ValidateNewLocationForm {
  form: NewLocationForm;
}

export function validateNewLocationForm({ form }: ValidateNewLocationForm) {
  const { address1, address2, city, state, zipCode } = form;

  const fieldWarnings: Validations = {}

  const fieldErrors: Validations = {
    address1: address1 === "" || address1.length > 100,
    address2: address2.length > 50,
    city: city === "" || city.length > 50,
    state: state === "",
    zipCode: zipCode === "" || zipCode.length > 10 || zipCode.length < 5
  }

  const isValid = Object.values(fieldErrors).every(item => item === false);

  return { fieldErrors, fieldWarnings, isValid };
}

// Validation for NewCaseForm
interface ValidateNewCaseForm {
  state: State;
}

export function validateNewCaseForm({ state }: ValidateNewCaseForm) {
  const { newCaseForm: { employeeNumber, positiveTestDate, awarePositiveDate } } = state;

  const fieldWarnings: Validations = {
    employeeNumber: (/^\d{3}-?\d{2}-?\d{4}$/.test(employeeNumber)),
  }

  const fieldErrors: Validations = {
    employeeNumber: employeeNumber === "",
    positiveTestDate: positiveTestDate === "",
    awarePositiveDate: awarePositiveDate === "" || compareDateStrings(awarePositiveDate, "<", positiveTestDate),
  }

  const isValid = Object.values(fieldErrors).every(item => item === false);

  return { fieldErrors, fieldWarnings, isValid };
}


// Validations for EditNewCaseForm
interface ValidateEditNewCaseForm {
  form: EditCaseForm;
}

export function validateEditCaseForm({ form }: ValidateEditNewCaseForm) {
  const { employeeNumber, positiveTestDate, awarePositiveDate, lastDayOnSite, location, employeesOnSite } = form;

  const fieldWarnings: Validations = {}

  const fieldErrors: Validations = {
    employeeNumber: employeeNumber === "",
    positiveTestDate: positiveTestDate === "",
    awarePositiveDate: awarePositiveDate === "" || compareDateStrings(awarePositiveDate, "<", positiveTestDate),
    lastDayOnSite: lastDayOnSite === "" || compareDateStrings(lastDayOnSite, ">", positiveTestDate),
    location: location === "",
    employeesOnSite: employeesOnSite === "" || !/^\d+$/.test(employeesOnSite) || parseInt(employeesOnSite) <= 0,
  }

  const isValid = Object.values(fieldErrors).every(item => item === false);

  return { fieldErrors, fieldWarnings, isValid };
}


// Validations for NewCaseFormLocations
interface ValidateNewCaseFormLocations {
  state: State;
}

export interface ValidationStack {
  ID: number;
  fieldErrors: Validations;
  fieldWarnings: Validations;
}

export function validateNewCaseFormLocations({ state }: ValidateNewCaseFormLocations) {
  const { newCaseFormLocations, newCaseForm } = state;
  const validations: ValidationStack[] = [];

  if (newCaseFormLocations.length === 0) {
    return { validations, isValid: false };
  }

  for (let location of newCaseFormLocations) {
    const { lastDayOnSite } = location;
    validations.push({
      ID: location.locationId,
      fieldWarnings: {},
      fieldErrors: {
        lastDayOnSite: lastDayOnSite === "" || compareDateStrings(lastDayOnSite, ">", newCaseForm.positiveTestDate),
        employeesOnSite: location.employeesOnSite === "" || !/^\d+$/.test(location.employeesOnSite) || parseInt(location.employeesOnSite) <= 0,
      },
    });
  }

  const isValid = validations.every(validation => Object.values(validation.fieldErrors).every(item => item === false));

  return { validations, isValid };
}