import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { StatesUS } from '../../../../Static/StatesUS';
import { FieldValidationMessage } from '../../../../Utils/Components';
import MessageToolTips from '../../MessageToolTips';
import { Validations } from '../Validations';
import { NewLocationForm } from './Main';

function RedAsterisk() {
    return <span className="text-danger"> *</span>
}

interface FormProps {
    form: NewLocationForm;
    fieldErrors: Validations;
    hasSubmitted: boolean;
    onFormEvent: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
}

export default function LocationModalForm({ form, fieldErrors, hasSubmitted, onFormEvent }: FormProps) {
    return (
        <Form as={Row}>
            <Form.Group as={Col} md={12} controlId="address1" className="py-2">
                <Form.Label>Street 1<RedAsterisk /></Form.Label>
                <Form.Control className="p-1" type="text" name="address1" value={form.address1} onChange={onFormEvent} />
                <FieldValidationMessage display={hasSubmitted && fieldErrors.address1} message="Field must have 1 to 100 characters" type="danger" />
            </Form.Group>
            <Form.Group as={Col} md={12} controlId="address2" className="py-2">
                <Form.Label>Street 2 (Street, Field, Building #, etc.)</Form.Label>
                <MessageToolTips prompt='Address 2' helpText='' />
                <Form.Control className="p-1" type="text" name="address2" value={form.address2} onChange={onFormEvent} />
                <FieldValidationMessage display={hasSubmitted && fieldErrors.address2} message="Field can have a maximum of 50 characters" type="danger" />
            </Form.Group>
            <Form.Row as={Col} md={12} className="pt-2 pb-4">
                <Form.Group as={Col} controlId="city" className="pr-2">
                    <Form.Label>City<RedAsterisk /></Form.Label>
                    <Form.Control className="p-1" type="text" name="city" value={form.city} onChange={onFormEvent} />
                    <FieldValidationMessage display={hasSubmitted && fieldErrors.city} message="Field must have 1 to 50 characters" type="danger" />
                </Form.Group>
                <Form.Group as={Col} controlId="state" className="px-2">
                    <Form.Label>State<RedAsterisk /></Form.Label>
                    <Form.Control className="p-1" as="select" name="state" value={form.state} onChange={onFormEvent} >
                        <option disabled value="">Please Select</option>
                        {StatesUS.map(state =>
                            <option value={state.abbreviation} key={state.abbreviation}>{state.abbreviation}</option>
                        )}
                    </Form.Control>
                    <FieldValidationMessage display={hasSubmitted && fieldErrors.state} message="Field cannot be empty" type="danger" />
                </Form.Group>
                <Form.Group as={Col} controlId="zipCode" className="pl-2">
                    <Form.Label>Zipcode<RedAsterisk /></Form.Label>
                    <Form.Control className="p-1" type="text" name="zipCode" value={form.zipCode} onChange={onFormEvent} />
                    <FieldValidationMessage display={hasSubmitted && fieldErrors.zipCode} message="Field must have 5 to 10 characters" type="danger" />
                </Form.Group>
            </Form.Row>
        </Form>
    );
}