import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import { Location } from '../../../../Contexts/State/Types';
import { FieldValidationMessage, RedAsterisk } from '../../../../Utils/Components';
import { dateToMMDDYYYY, dateToYYYYMMDD, locationFormat, stringToMMDDYYYY } from '../../../../Utils/Utils';
import { EditCaseForm } from './Main';

interface Props {
    form: EditCaseForm;
    locations: Location[];
    fieldErrors: Record<string, boolean>;
    hasSubmitted: boolean;
    onFormEvent: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
}

export default function EditCaseModalForm({ form, locations, hasSubmitted, fieldErrors, onFormEvent }: Props) {
    const handleFormUpdateForDate = (e: React.SyntheticEvent<any, Event> | undefined, date: Date | [Date, Date] | null, name: string) => {
        (e as React.ChangeEvent<HTMLInputElement>).target.name = name;
        if (date instanceof Date)
            (e as React.ChangeEvent<HTMLInputElement>).target.value = dateToYYYYMMDD({ date, separator: "-" });
        onFormEvent(e as React.ChangeEvent<HTMLInputElement>);
    }

    const dateMin = new Date("07/06/2020");
    const dateMax = new Date(dateToMMDDYYYY({ separator: "/" }));
    const datePositiveMax = new Date("12/31/2023");
    const formatPositive = form.positiveTestDate === "" ? null : new Date(stringToMMDDYYYY({ date: form.positiveTestDate, separator: "/" }));
    const formatAware = form.awarePositiveDate === "" ? null : new Date(stringToMMDDYYYY({ date: form.awarePositiveDate, separator: "/" }))
    const formatLast = form.lastDayOnSite === "" ? null : new Date(stringToMMDDYYYY({ date: form.lastDayOnSite, separator: "/" }))

    return (
        <Form as={Row} id="editCaseModal">
            <Form.Group as={Col} md={6} controlId="employeeNumber" className="py-2 pr-2" >
                <Form.Label>Employee ID<RedAsterisk /></Form.Label>
                <Form.Control className="p-1" type="text" name="employeeNumber" value={form.employeeNumber} onChange={onFormEvent} />
                <FieldValidationMessage display={hasSubmitted && fieldErrors.employeeNumber} message="Field cannot be empty" type="danger" />
            </Form.Group>
            <Form.Group as={Col} controlId="location" className="py-2 pl-2">
                <Form.Label>State<RedAsterisk /></Form.Label>
                <Form.Control className="p-1" as="select" name="location" value={form.location} onChange={onFormEvent} >
                    <option disabled value="">Please Select</option>
                    {locations.map(location =>
                        <option value={locationFormat(location)} key={locationFormat(location)}>{locationFormat(location)}</option>
                    )}
                </Form.Control>
                <FieldValidationMessage display={hasSubmitted && fieldErrors.location} message="Field cannot be empty" type="danger" />
            </Form.Group>
            <Form.Group as={Col} md={6} className="py-2 pr-2">
                <Form.Label>Positive Test Date before 2024<RedAsterisk /></Form.Label>
                <DatePicker className="p-1 form-control" id="positiveTestDate" selected={formatPositive} onChange={(date, e) => handleFormUpdateForDate(e, date, "positiveTestDate")} minDate={dateMin} maxDate={datePositiveMax} placeholderText="mm/dd/yyyy" />
                <FieldValidationMessage display={hasSubmitted && fieldErrors.positiveTestDate} message="Field cannot be empty" type="danger" />
            </Form.Group>
            <Form.Group as={Col} md={6} className="py-2 pl-2">
                <Form.Label>Aware Positive Date<RedAsterisk /></Form.Label>
                <DatePicker className="p-1 form-control w-100" id="awarePositiveDate" selected={formatAware} onChange={(date, e) => handleFormUpdateForDate(e, date, "awarePositiveDate")} minDate={dateMin} maxDate={dateMax} placeholderText="mm/dd/yyyy" />
                <FieldValidationMessage display={hasSubmitted && fieldErrors.awarePositiveDate} message="Must be on or after positive test date" type="danger" />
            </Form.Group>
            <Form.Group as={Col} md={6} className="py-2 pr-2">
                <Form.Label>Last Day on Site<RedAsterisk /></Form.Label>
                <DatePicker className="p-1 form-control" id="lastDayOnSite" selected={formatLast} onChange={(date, e) => handleFormUpdateForDate(e, date, "lastDayOnSite")} minDate={dateMin} maxDate={dateMax} placeholderText="mm/dd/yyyy" />
                <FieldValidationMessage display={hasSubmitted && fieldErrors.lastDayOnSite} message="Must on or before positive test date" type="danger" />
            </Form.Group>
            <Form.Group as={Col} md={6} controlId="employeesOnSite" className="py-2 pl-2">
                <Form.Label># of Employees<RedAsterisk /></Form.Label>
                <Form.Control className="p-1" type="text" name="employeesOnSite" value={form.employeesOnSite} onChange={onFormEvent} />
                <FieldValidationMessage display={hasSubmitted && fieldErrors.employeesOnSite} message="Field cannot be empty and must be number greater than 0" type="danger" />
            </Form.Group>
        </Form >
    )
}
