import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, OverlayTrigger, Popover, Row } from 'react-bootstrap';
import { getLocationsByPolicy } from '../../../../Actions/Location';
import { useSetStateContext, useStateContext } from '../../../../Contexts/State/Context';
import { locationFormat, sortLocations } from '../../../../Utils/Utils';

const popperTexts = {
    copy: "Use the copy button if you need to add additional location details to an existing location on the list",
    edit: "Correct an address you entered previously – note, this will not change this address for PREVIOUSLY entered cases",
    delete: "If you no longer need this address, you can remove it from your list – this will not remove this address from PREVIOUSLY entered cases",
}

interface LocationDisplay {
    handleOpenEditOrCopy: (id: number) => void;
    handleDelete: (e: React.MouseEvent<HTMLElement, MouseEvent>, id: number) => void;
}

export default function LocationDisplay({ handleOpenEditOrCopy, handleDelete }: LocationDisplay) {
    const setState = useSetStateContext();
    const { policyNumber, locations } = useStateContext();

    const [loadingCall, setLoadingCall] = useState({
        isLoading: false,
        hasCalled: false
    });

    const refresh = useCallback(() => {
        getLocationsByPolicy({ policyNumber: policyNumber ?? "", setLoadingCall, setState });
    }, [policyNumber, setState]);

    useEffect(() => {
        !loadingCall.hasCalled &&
            refresh();
    }, [loadingCall.hasCalled, policyNumber, setLoadingCall, setState, refresh])

    const sorted = sortLocations(locations);


    const getPopper = (message: string, id: string) => (
        <Popover id={`tooltip-${id}`}>
            <Popover.Content className="p-2 text-primary">
                {message}
            </Popover.Content>
        </Popover>
    );

    return loadingCall.isLoading
        ?
        <p>Loading</p>
        :
        <div className="row justify-content-between mt-2">
            <div className="col-12">
                <p className="font-weight-bold">Addresses
                    <a id="location-refresh" role='button' className='btn ml-2' onClick={refresh} >
                        <i className="fas fa-sync btn-outline-primary"></i>
                    </a>
                </p>
                <div style={sorted?.length > 10 ? { maxHeight: "240px", overflowY: "scroll" } : undefined}>
                    {sorted.map((location) =>
                        <Row className="align-items-center" key={location.locationId.toString()}>
                            <Col className="text-truncate">
                                <p key={location.locationId} className="text-truncate">{locationFormat(location)}</p>
                            </Col>
                            <Col className="col-auto" style={{ width: "60px" }}>
                                <Row className="justify-content-end">
                                    {location.locationId < 0
                                        ?
                                        <OverlayTrigger placement="bottom" overlay={getPopper(popperTexts.copy, "copy-location")}>
                                            <Button id="location-copy" className="border-0 bg-attention p-0 px-1" onClick={() => handleOpenEditOrCopy(location.locationId)}>
                                                <i className="text-primary far fa-copy" style={{ fontSize: "16px", height: "16px", width: "16px" }}></i>
                                            </Button>
                                        </OverlayTrigger>
                                        :
                                        <OverlayTrigger placement="bottom" overlay={getPopper(popperTexts.edit, "edit-location")}>
                                            <Button id="location-edit" className="border-0 bg-attention p-0 px-1" onClick={() => handleOpenEditOrCopy(location.locationId)}>
                                                <i className="text-primary fas fa-edit" style={{ fontSize: "16px", height: "16px", width: "16px" }}></i>
                                            </Button>
                                        </OverlayTrigger>
                                    }
                                    <OverlayTrigger placement="bottom" overlay={getPopper(popperTexts.delete, "delete-location")}>
                                        <Button id="location-delete" className={`border-0 bg-attention p-0 px-1 ${location.locationId >= 0 ? "" : "invisible"}`} onClick={e => handleDelete(e, location.locationId)}>
                                            <i className="text-primary far fa-trash-alt" style={{ fontSize: "16px", height: "16px", width: "16px" }}></i>
                                        </Button>
                                    </OverlayTrigger>
                                </Row>
                            </Col>
                        </Row>
                    )}
                </div>
            </div >
        </div >
}

// TODO - For edit and delete locations later
//<div className="col-1 row">
//    <p className="col-12" style={{ opacity: 0 }}>hidden</p>
//    {locations.map(({ id, canDelete }) =>
//        canDelete ?
//            <button className="col-12 border-0 p-0 bg-attention" onClick={() => handleDeleteLocation(id)} >
//                <i className="far fa-trash-alt text-gray" style={{ fontSize: "16px" }}></i>
//            </button>
//            :
//            <button className="col-12 border-0 p-0 bg-attention">
//                <i className="fas fa-edit text-primary" style={{ fontSize: "16px" }}></i>
//            </button>
//    )}
//</div>