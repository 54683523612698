import React, { useEffect } from 'react';
import { maxContainerWidth } from '../../Contexts/State/Constants';
import { useSetStateContext, useStateContext } from '../../Contexts/State/Context';
import { getByPage } from '../../Data/Message';
import { useWindowSize } from '../../Hooks/UseWindowSize';
import QuestionnaireContent from './Questionnaire/Content/Main';
import QuestionnaireSide from './Questionnaire/Side/Main';
import ReportContent from './Report/Content/Main';
import ReportSide from './Report/Side/Main';
import Top from './Report/Top/Main';
import ThankYou from './ThankYou/Content/Main';


export default function Home() {
    // State
    const state = useStateContext();
    const setState = useSetStateContext();

    // Hooks
    const { width } = useWindowSize();
    const containerWidth = width - (width - maxContainerWidth) / 2 + 22;

    // Load the help texts used by covid tracker
    useEffect(() => {
        async function getMessages() {
            const data = await getByPage();
            if (data) {
                //debugger;
                setState(state => ({ ...state, messages: data }));
            }
        }

        getMessages();
    }, [setState]);

    return state.displayReporting
        ?
        <div className="row justify-content-center">
            <div className="row container-fluid py-2 px-4 mx-auto" style={{ maxWidth: maxContainerWidth }}>
                <div className="col-lg-12">
                    <Top />
                </div>
                <div className="col-lg-8 pr-lg-2 mt-3">
                    <ReportContent />
                </div>
                <div className="col-lg-4 pl-lg-2 mt-3">
                    <ReportSide />
                </div>
            </div>
        </div>
        :
        <div className="row justify-content-md-end" style={{ minHeight: "inherit" }}>
            <div className="row container-fluid justify-content-between pl-4" style={{ minHeight: "inherit", maxWidth: `${containerWidth}px` }}>
                <div className="col-12 col-lg-6 pr-4 pr-md-5 h-100">
                    {state.displayThankYou ? <ThankYou /> : <QuestionnaireContent />}
                </div>
                <div className="col-5 pl-md-6 d-none d-lg-block" style={{ minHeight: "inherit" }}>
                    <QuestionnaireSide />
                </div>
            </div>
        </div>
}
