import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import { editNewCaseFormLocations } from '../../../../Actions/Employee';
import { useSetStateContext, useStateContext } from '../../../../Contexts/State/Context';
import { useWindowSize } from '../../../../Hooks/UseWindowSize';
import { FieldValidationMessage, RedAsterisk } from '../../../../Utils/Components';
import { compareDateStrings, dateToMMDDYYYY, dateToYYYYMMDD, sortNewCaseFormLocations, stringToMMDDYYYY } from '../../../../Utils/Utils';
import MessageToolTips from '../../MessageToolTips';
import { ValidationStack } from '../Validations';

interface Props {
    validations: ValidationStack[];
    hasSubmitted: boolean;
    setHasSubmitted: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function NewCaseFormLocations({ validations, hasSubmitted, setHasSubmitted }: Props) {
    // Context
    const state = useStateContext();
    const { newCaseForm, newCaseFormLocations } = state;
    const setState = useSetStateContext();

    // hooks
    const { width } = useWindowSize();

    // Events
    const handleSelectedLocationEdit = (e: React.ChangeEvent<HTMLInputElement>) => {
        setHasSubmitted(false);
        editNewCaseFormLocations({ e, state, setState });
    }

    // Variables
    const dateMin = new Date("07/06/2020");
    const dateMax = new Date(dateToMMDDYYYY({ separator: "/" }));

    const handleFormUpdateForDate = (e: React.SyntheticEvent<any, Event> | undefined, date: Date | [Date, Date] | null, name: string, id: string) => {
        (e as React.ChangeEvent<HTMLInputElement>).target.name = name;
        (e as React.ChangeEvent<HTMLInputElement>).target.id = id;
        if (date instanceof Date)
            (e as React.ChangeEvent<HTMLInputElement>).target.value = dateToYYYYMMDD({ date, separator: "-" });
        handleSelectedLocationEdit(e as React.ChangeEvent<HTMLInputElement>);
    }

    function getLastDayOnSitevalue(lastDayOnSite: string) {
        return lastDayOnSite === "" ? null : new Date(stringToMMDDYYYY({ date: lastDayOnSite, separator: "/" }));
    }

    function createPlaceholder(lastDayOnSite: string, positiveTestDate: string) {
        if (lastDayOnSite === "") return "Select last day on site";
        return compareDateStrings(positiveTestDate, "<=", "09/17/2020") ?
            "from 07/06/2020 to 09/17/2020" :
            `from ${stringToMMDDYYYY({ date: lastDayOnSite, separator: "/", offsetDays: -45 })} to ${stringToMMDDYYYY({ date: lastDayOnSite, separator: "/" })}`;
    }

    const sorted = sortNewCaseFormLocations(newCaseFormLocations);

    return newCaseFormLocations.length > 0 ?
        <>
            <Row className="w-100 py-3">
                <Col>
                    <span>Location Employee Visited</span>
                </Col>
                {width >= 768 &&
                    <>
                        <Col md={4} className="pr-md-2">
                            <span>Last Day on Site<RedAsterisk /></span>
                            <MessageToolTips prompt='Last Day on Site' helpText='' />
                        </Col>
                        <Col md={4} className="pl-md-2">
                            <span># Employees on Site<RedAsterisk /></span>
                            <MessageToolTips prompt='Number Of Employees On Site Between <<Date1>> and <<Date2>>' helpText='' />
                        </Col>
                    </>
                }
            </Row>
            <hr className="w-100" />
            <Row className="w-100">
                {sorted.map((location) => {
                    const displayLastDayOnSite = validations.find(validation => validation.ID === location.locationId)?.fieldErrors.lastDayOnSite || false;
                    const displayEmployeesOnSite = validations.find(validation => validation.ID === location.locationId)?.fieldErrors.employeesOnSite || false;
                    const placeholder = createPlaceholder(location.lastDayOnSite, newCaseForm.positiveTestDate);
                    return (
                        <Row className="col-12 row align-items-center py-3" key={location.locationId.toString()}>
                            <Col xs={12} md={4} className="mb-2 pr-md-4">{location.location}</Col>
                            <Form.Group as={Col} xs={6} md={4} className="pr-2" >
                                {width < 768 && <Form.Label>Last Day on Site<RedAsterisk /></Form.Label>}
                                <DatePicker className="p-1 form-control" selected={getLastDayOnSitevalue(location.lastDayOnSite)} onChange={(date, e) => handleFormUpdateForDate(e, date, "lastDayOnSite", location.locationId.toString())} minDate={dateMin} maxDate={dateMax} placeholderText="mm/dd/yyyy" />
                                <FieldValidationMessage display={hasSubmitted && displayLastDayOnSite} message="Must on or before positive test date" type="danger" />
                            </Form.Group>
                            <Form.Group as={Col} xs={6} md={4} className="pl-2" >
                                {width < 768 && <Form.Label># Employees on Site<RedAsterisk /></Form.Label>}
                                <Form.Control className="p-1" type="text" disabled={location.lastDayOnSite === ""} id={location.locationId.toString()} value={location.employeesOnSite.toString()} name="employeesOnSite" onChange={handleSelectedLocationEdit} placeholder={placeholder} />
                                <FieldValidationMessage display={hasSubmitted && displayEmployeesOnSite} message="Field cannot be empty and must be number greater than 0" type="danger" />
                            </Form.Group>
                        </Row>
                    )
                }
                )}
            </Row>
        </>
        :
        null;
}
