import { QuestionnaireForm } from "./Content/Main";

// General form of a validation
export type Validations = Record<string, boolean>

// Validation for add new location
interface ValidateQuestionnaireForm {
  form: QuestionnaireForm;
}

export function validateQuestionnairForm({ form }: ValidateQuestionnaireForm) {
  const { policyNumber, firstName, lastName, email, phone } = form;

  const fieldWarnings: Validations = {}

  const fieldErrors: Validations = {
    policyNumber: policyNumber === "" || !/^\D{4}\d{6}$/.test(policyNumber),
    firstName: firstName === "" || firstName.length > 100,
    lastName: lastName === "" || lastName.length > 100,
    email: email === "" || email.length > 256 || !/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email),
    phone: phone === "" || !/^\D*(\d\D*){10}$/.test(phone)
  }

  const isValid = Object.values(fieldErrors).every(item => item === false);

  return { fieldErrors, fieldWarnings, isValid };
}
