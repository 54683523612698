import React, { useState } from "react";

export interface Update {
    type: string;
    name: string;
    value: string;
    form: any
}

// Creates a hook for any form
// Handles its own state, exposes custom controls and values
// onFormEvent HAS TO BE PASSED TO FORM COMPONENTS FOR ONCHANGE
export function useForm<T>({ initialData, modifier = ({ type, name, value, form }: Update): T => form }: { "initialData": T, modifier?: (Modifier: any) => T }) {
    const [form, setForm] = useState<T>(initialData);
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [lastInput, setLastInput] = useState<Record<"type" | "name" | "value", string>>({ type: "", name: "", value: "" });

    function onFormEvent(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
        hasSubmitted && setHasSubmitted(false);
        const { type, name, value } = e.target;
        const prev = String((form as any)[name]);
        setLastInput({ type, name, value });
        setForm(data => ({ ...(modifier({ type, name, value, form: data })), [name]: convertFormValue({ type, value, prev }) }));
    }

    function resetForm() {
        setHasSubmitted(false);
        setForm(initialData);
    }

    return { onFormEvent, form, setForm, resetForm, lastInput, hasSubmitted, setHasSubmitted };
}

// All form values are submitted as strings
// Converts form values to value that make sense
// Example: radio types should be a boolean, not "true" or "false"
interface ConvertFormValue {
    type: string;
    value: string;
    prev: string | null;
}

function convertFormValue({ type, value, prev }: ConvertFormValue) {
    switch (type) {
        case "radio":
            return value === "true" ? true : value === "false" ? false : value;
        case "tel":
            const onlyNumbers = value.match(/\d+/g)?.join("").slice(0, 10) || "";
            const mask1 = /^\((\d|_){3}\) (\d|_){3}-(\d|_){3}$/;
            const mask2 = /^\((\d|_){3}\) (\d|_){3}-(\d|_){5}$/;
            if (mask1.test(value)) return prev?.length === onlyNumbers.length ? onlyNumbers.slice(0, -1) : onlyNumbers;
            if (mask2.test(value)) return onlyNumbers.slice(0, 10);
            return onlyNumbers;
        default:
            return value;
    }
}

interface ApplyMask {
    value: string;
}

// Utility Function - Applies a mask to a phone number
export function applyPhoneMask({ value }: ApplyMask) {
    let numbers = value.match(/\d+/g)?.join("");
    let placeholder = `(___) ___-____`;
    if (numbers) {
        for (let num of numbers) {
            placeholder = placeholder.replace("_", num);
        }
    }
    return placeholder;
}
