import React, { useEffect, useRef, useState } from 'react';
import { useSetStateContext, useStateContext } from '../../../../Contexts/State/Context';
import { useWindowSize } from '../../../../Hooks/UseWindowSize';
import { ContinueToForm } from '../Actions';
import { redirectToClaims } from '../Events';
import { QuestionnaireForm } from './Main';
import MessageToolTips from '../../MessageToolTips';

interface Props {
    form: QuestionnaireForm;
    onFormEvent: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const radioStyle = {
    height: "16px",
    width: "16px",
}

export default function ReportingQuestions({ form, onFormEvent }: Props) {
    const { } = useStateContext();
    const setState = useSetStateContext();
    const [height, setHeight] = useState(0);
    const { width } = useWindowSize();

    const ref1 = useRef<null | HTMLDivElement>(null);
    const ref2 = useRef<null | HTMLDivElement>(null);
    const ref3 = useRef<null | HTMLDivElement>(null);
    const ref4 = useRef<null | HTMLDivElement>(null);
    const ref5 = useRef<null | HTMLDivElement>(null);
    const ref6 = useRef<null | HTMLDivElement>(null);
    const ref7 = useRef<null | HTMLDivElement>(null);
    const ref8 = useRef<null | HTMLDivElement>(null);
    const ref9 = useRef<null | HTMLDivElement>(null);
    const ref10 = useRef<null | HTMLDivElement>(null);
    const ref11 = useRef<null | HTMLDivElement>(null);

    useEffect(() => {
        let height = 320;
        if (ref1.current) height += ref1.current.clientHeight;
        if (ref2.current) height += ref2.current.clientHeight;
        if (ref3.current) height += ref3.current.clientHeight;
        if (ref4.current) height += ref4.current.clientHeight;
        if (ref5.current) height += ref5.current.clientHeight;
        if (ref6.current) height += ref6.current.clientHeight;
        if (ref7.current) height += ref7.current.clientHeight;
        if (ref8.current) height += ref8.current.clientHeight;
        if (ref9.current) height += ref9.current.clientHeight;
        if (ref10.current) height += ref10.current.clientHeight;
        if (ref11.current) height += ref11.current.clientHeight;
        setHeight(height);
    }, [width])

    return (
        <div style={{ height: `${height}px` }}>
            <div ref={ref1} className="pb-2 mb-2" >
                <h5 className="col-12">California Labor Code 3212.88, established under Senate Bill 1159, requires employers to submit information to their claims administrator when they know, or reasonably should know, that an employee tested positive for COVID-19.  This site will help you identify whether COVID positive employees need to be reported as a WC claim or submitted for tracking purposes and offers an easy and convenient way to provide the necessary details to us.</h5>
            </div>
            <div ref={ref11} className="pb-2 mb-2" >
                <h5 className="col-12">California Labor Code 3212.88 will sunset on 1/1/24, any employee who has tested positive for COVID-19 prior to 1/1/24 will need to be retroactively reported through this tracker. Any positive test after 1/1/24 does not need to be tracked but may be reported as a claim. </h5>
            </div>
            <div ref={ref10} className="p-3 mb-2" style={{ border: "3px solid red" }}>
                <h3>You will need <strong><u>ALL</u></strong> of the following items before you proceed:</h3>
                <ol className="p-3">
                    <li>Valid BHHC policy number</li>
                    <li>Employee ID – If you don’t have this, you can create your own. <strong><u>NO</u> names or parts of SSN should be used.</strong></li>
                    <li>Employee COVID test date *. Qualifying tests: PCR, Rapid test; (FDA approved home Antigen tests – per OSHA standards.  Not Antibody tests). * Not valid for test dates 1/1/24 and after.</li>
                    <li>Date employer knew employee tested positive </li>
                    <li>Location(s) employees worked in the 14 days <strong><u>BEFORE</u></strong> testing positive<br />“A specific place of employment” means a building, store, facility, or agriculture field where an employee performs work at the employer’s direction.<br />“A specific place of employment” does not include the employee’s home or residence, unless the employee provides home health care services to another individual at the employee’s home or residence.</li>
                    <li>Last date employee worked at each location <strong><u>BEFORE</u></strong> testing positive</li>
                    <li>Highest number of employees at each location in the 45 days before the employee’s last day worked</li>
                </ol>
            </div>
            <div className="form-group col-12 pt-2">
                <h5 className="col-12 font-weight-bold pb-2">Please answer the following questions to continue</h5>
                <p ref={ref2}>Have you been notified (or received notification) that the employee believes that COVID-19 is work related or was contracted at work? <b>If you’re not sure, please select 'No' and proceed.</b></p>
                <div ref={ref3} className="form-group col-12 pt-2 row align-items-center">
                    <input type="radio" className="form-control-inline mr-2" id="isWorkRelatedYes" checked={form.isWorkRelated === true} name="isWorkRelated" value="true" onChange={onFormEvent} style={radioStyle} />
                    <label htmlFor="isWorkRelatedYes" className="pr-5">Yes</label>
                    <input type="radio" className="form-control-inline mr-2" id="isWorkRelatedNo" checked={form.isWorkRelated === false} name="isWorkRelated" value="false" onChange={onFormEvent} style={radioStyle} />
                    <label htmlFor="isWorkRelatedNo">No</label>
                </div>
                <div className={`col-12 pt-1 ${form.isWorkRelated !== true ? "d-none" : ""}`}>
                    <button className="btn btn-primary mt-2 mb-2" onClick={redirectToClaims}>Report Claim</button>
                </div>
            </div>
            <div className={`form-group col-12 pt-2 ${form.isWorkRelated === false ? "" : "invisible"}`}>
                <p ref={ref4}>Did the employee report they tested positive prior to 1/1/24? <MessageToolTips prompt='Qualifying tests' helpText='Qualifying tests:' /> PCR, Rapid test; (FDA approved home Antigen tests-per OSHA standards. Not Antibody tests)</p>
                <div ref={ref5} className="form-group col-12 pt-2 row align-items-center">
                    <input type="radio" className="form-control-inline mr-2" id="isOnSiteYes" checked={form.isOnSite === true} name="isOnSite" value="true" onChange={onFormEvent} style={radioStyle} />
                    <label htmlFor="isOnSiteYes" className="pr-5">Yes</label>
                    <input type="radio" className="form-control-inline mr-2" id="isOnSiteNo" checked={form.isOnSite === false} name="isOnSite" value="false" onChange={onFormEvent} style={radioStyle} />
                    <label htmlFor="IsOnSiteNo">No</label>
                </div>
                <div className={`${form.isOnSite === false ? "" : "d-none"}`}>
                    <p className="pt-4 pb-3">You do not have to submit information for tracking purposes unless an employee reports they tested positive for COVID-19.</p>
                    <a className="font-weight-bold" href="http://www.bhhccovid19.com/"><i className="fas fa-arrow-left mr-2"></i>Back to BHHC Covid Information</a>
                </div>
            </div>
            <div className={`form-group col-12 pt-2 ${form.isOnSite === true ? "" : "invisible"}`}>
                <p ref={ref6} >Did the employee(s) work on-site at one of your locations on or after 7/6/20 through 12/31/23?</p>
                <div ref={ref7} className="form-group col-12 pt-2 row align-items-center">
                    <input type="radio" className="form-control-inline mr-2" id="isPositiveYes" checked={form.isPositive === true} name="isPositive" value="true" onChange={onFormEvent} style={radioStyle} />
                    <label htmlFor="isPositiveYes" className="pr-5">Yes</label>
                    <input type="radio" className="form-control-inline mr-2" id="isPositiveNo" checked={form.isPositive === false} name="isPositive" value="false" onChange={onFormEvent} style={radioStyle} />
                    <label htmlFor="IsPositiveNo">No</label>
                </div>
                <div className={`${form.isPositive === false ? "" : "d-none"}`}>
                    <p className="pt-4 pb-3">You do not have to submit information for tracking purposes if a COVID-19 positive employee did not work on-site at one of your locations on or after 7/6/20.</p>
                    <a className="font-weight-bold" href="http://www.bhhccovid19.com/"><i className="fas fa-arrow-left mr-2"></i>Back to BHHC Covid Information</a>
                </div>
            </div>
            <div className={`form-group col-12 pt-2 ${form.isPositive === true ? "" : "invisible"}`}>
                <p ref={ref8}>Did the employee work on-site at one of your locations at any time within 14 days prior to the test date?</p>
                <div ref={ref9} className="form-group col-12 pt-2 row align-items-center">
                    <input type="radio" className="form-control-inline mr-2" id="isOnSite14DaysPriorYes" checked={form.isOnSite14DaysPrior === true} name="isOnSite14DaysPrior" value="true" onChange={onFormEvent} style={radioStyle} />
                    <label htmlFor="IsOnSite14DaysPriorYes" className="pr-5">Yes</label>
                    <input type="radio" className="form-control-inline mr-2" id="isOnSite14DaysPriorNo" checked={form.isOnSite14DaysPrior === false} name="isOnSite14DaysPrior" value="false" onChange={onFormEvent} style={radioStyle} />
                    <label htmlFor="IsOnSite14DaysPriorNo">No</label>
                </div>
                <div className={`${form.isOnSite14DaysPrior === false ? "" : "d-none"}`}>
                    <p className="pt-4 pb-3">You do not have to submit information for tracking purposes if a COVID-19 positive employee did not work on-site at one of your locations, for any period of time, in the 14-days prior to the test date.</p>
                    <a className="font-weight-bold" href="http://www.bhhccovid19.com/"><i className="fas fa-arrow-left mr-2"></i>Back to BHHC Covid Information</a>
                </div>
            </div>
            <div className={`${form.isOnSite14DaysPrior !== true ? "d-none" : ""}`}>
                <p className="pt-4 pb-3">It appears that you have employees who meet the Covid tracking requirements under SB1159. Please proceed to submit the information.</p>
                <button type="button" className="btn btn-primary mt-2" style={{ width: "200px" }} onClick={() => ContinueToForm({ setState })}>Next Steps</button>
            </div>
        </div>
    );
}
