import { State } from "./Types"

export const initialState: State = {
    // Page display for home route
    displayQuestionnaireForm: false,
    displayThankYou: false,

    // set to true to be on reporting page
    displayReporting: false,
    // displayReporting: true,

    displayLoadingModal: false,

    // Global parameters used across all components
    reportCreatedAt: "",

    // Uncomment when just testing report page if you need locations
    reportId: 0,
    policyNumber: "",
    insuredName: "",
    submitter: "",
    // reportId: 2,
    // policyNumber: "UNWC104497",
    // insuredName: "United Agribusiness League",
    // submitter: "Test Submitter",

    // Locations list on side of report page
    locations: [],

    // Case form data on report page
    newCaseForm: {
        employeeNumber: "",
        positiveTestDate: "",
        awarePositiveDate: "",
    },

    // Case form data for each selected location on report page
    newCaseFormLocations: [],

    // Cases added to table
    cases: [],

    // uncomment to have cases already rendered
    // cases: [
    //     { "caseId": 2438, "locationId": 3126, "employeeNumber": "test", "positiveTestDate": "2021-01-29", "awarePositiveDate": "2021-01-29", "location": "1365 Old Temescal Road 11, Suite 101 and Suite 102, Corona, CA 92881", "employeesOnSite": "123", "lastDayOnSite": "2021-01-29", "reportDate": "" },
    //     { "caseId": 2439, "locationId": 3121, "employeeNumber": "test", "positiveTestDate": "2021-01-29", "awarePositiveDate": "2021-01-29", "location": "1365 Old Temescal Road 7, Suite 101 and Suite 102, Corona, CA 92881", "employeesOnSite": "532", "lastDayOnSite": "2021-01-29", "reportDate": "" },
    //     { "caseId": 2440, "locationId": 3125, "employeeNumber": "test", "positiveTestDate": "2021-01-29", "awarePositiveDate": "2021-01-29", "location": "1365 Old Temescal Road 8, Suite 101 and Suite 102, Corona, CA 92881", "employeesOnSite": "534", "lastDayOnSite": "2021-01-29", "reportDate": "" },
    //     { "caseId": 2441, "locationId": 3124, "employeeNumber": "test", "positiveTestDate": "2021-01-29", "awarePositiveDate": "2021-01-29", "location": "1365 Old Temescal Road 9, Suite 101 and Suite 102, Corona, CA 92881", "employeesOnSite": "345", "lastDayOnSite": "2021-01-29", "reportDate": "" }
    // ],

    // Message tooltips when hovering
    messages: []
}
