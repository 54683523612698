import React, { createContext, useContext, useState } from "react";
import { initialState } from "./Data";
import { State } from "./Types";

const StateContext = createContext<State | null>(null);
const SetStateContext = createContext<React.Dispatch<React.SetStateAction<State>> | null>(null);

export default function StateProvider({ children }: Record<"children", JSX.Element>) {
    const [state, setState] = useState(initialState);
    return (
        <StateContext.Provider value={state}>
            <SetStateContext.Provider value={setState}>
                {children}
            </SetStateContext.Provider>
        </StateContext.Provider>
    )
}
function useStateContext() {
    const state = useContext(StateContext);
    if (!state) {
        throw new Error('Context of type State has one or more empty context values')
    }
    return state;
}

function useSetStateContext() {
    const setState = useContext(SetStateContext);
    if (!setState) {
        throw new Error('Context of type SetState has one or more empty context values')
    }
    return setState;
}

export { StateProvider, useStateContext, useSetStateContext }
