import React from 'react';
import { useStateContext } from '../../../../Contexts/State/Context';
import { Case } from '../../../../Contexts/State/Types';
import { stringToMMDDYYYY } from '../../../../Utils/Utils';
import { EditCaseForm } from './Main';

interface Props {
    setForm: React.Dispatch<React.SetStateAction<EditCaseForm>>;
    handleOpen: (caseItem: Case) => void;
    handleDelete: (caseId: number) => Promise<void>;
}

export default function CasesTable({ handleOpen, handleDelete }: Props) {
    // Context
    const state = useStateContext();
    const { cases } = state;

    return (
        <>
            <div className="pt-4">
                <h5>
                    Case Submitted Current Session
                </h5>
            </div>
            <div className="mt-2 border rounded table-responsive">
                <table id="casesTable" className="table table-striped text-center">
                    <thead className="bg-primary text-light">
                        <tr>
                            <th className="align-middle py-2 px-2">Report Date</th>
                            <th className="align-middle py-2 px-2 text-truncate">Employee ID</th>
                            <th className="align-middle py-2 px-2 text-left">Location</th>
                            <th className="align-middle py-2 px-2">Employees on Site</th>
                            <th className="align-middle py-2 px-2">Employer Notified On</th>
                            <th className="align-middle py-2 px-2">Last Day on Site</th>
                            <th className="align-middle py-2 px-2">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {cases.map((caseItem, i) =>
                            <tr key={i}>
                                <td className="px-2">{stringToMMDDYYYY({ date: caseItem.reportDate, separator: "/" })}</td>
                                <td className="px-2">{caseItem.employeeNumber}</td>
                                <td className="px-2 text-truncate" style={{ maxWidth: "180px" }} title={caseItem.location}>{caseItem.location}</td>
                                <td className="px-2">{caseItem.employeesOnSite}</td>
                                <td className="px-2">{stringToMMDDYYYY({ date: caseItem.awarePositiveDate, separator: "/" })}</td>
                                <td className="px-2">{stringToMMDDYYYY({ date: caseItem.lastDayOnSite, separator: "/" })}</td>
                                <td className="px-2">
                                    <div className="row">
                                        <button className={`col-6 border-0 p-0 ${i % 2 === 1 ? "bg-white" : null}`} data-toggle="modal" data-target="#editCaseModal" onClick={() => handleOpen(caseItem)}>
                                            <i className="fas fa-edit text-primary" style={{ fontSize: "16px" }}></i>
                                        </button>
                                        <button className={`col-6 border-0 p-0 ${i % 2 === 1 ? "bg-white" : null}`} onClick={() => handleDelete(caseItem.caseId)}>
                                            <i className="far fa-trash-alt text-gray" style={{ fontSize: "16px" }}></i>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </>
    )
}
