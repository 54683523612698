import { Location, NewCaseFormLocation, State } from "../Contexts/State/Types";
import { addRange, removeEmployee, updateEmployee } from "../Data/Employee";
import { EditCaseForm } from "../Pages/Home/Report/Content/Main";
import { locationFormat } from "../Utils/Utils";

interface Display {
    showReportNewCase: boolean;
    showAllWorkLocations: boolean;
    showReportNewCasesLocations: boolean;
}

// edit employee by clicking edit icon in table row
interface EditEmployee {
    e: React.FormEvent<HTMLFormElement>;
    form: EditCaseForm;
    setState: React.Dispatch<React.SetStateAction<State>>;
}

export async function editEmployee({ e, form, setState }: EditEmployee) {
    e.preventDefault();

    setState(state => ({ ...state, displayLoadingModal: true }))
    const editWasSuccessful = await updateEmployee({ form });

    if (editWasSuccessful) {
        setState(state => {
            const cases = [...state.cases];
            const index = cases.findIndex(caseItem => caseItem.caseId === form.caseId);

            if (index !== -1) {
                cases[index].employeeNumber = form.employeeNumber;
                cases[index].positiveTestDate = form.positiveTestDate;
                cases[index].awarePositiveDate = form.awarePositiveDate;
                cases[index].lastDayOnSite = form.lastDayOnSite;
                cases[index].location = form.location;
                cases[index].employeesOnSite = form.employeesOnSite;
            }

            return { ...state, cases }
        })
    } else {
        alert("Unable to update employee, please try again in a moment. If the condition continues please contact support.")
    }
    setState(state => ({ ...state, displayLoadingModal: false }))
}

// delete case from table by clicking trash can in table
interface DeleteEmployee {
    caseId: number;
    state: State;
    setState: React.Dispatch<React.SetStateAction<State>>;
}

export async function deleteEmployee({ caseId, state, setState }: DeleteEmployee) {
    setState(state => ({ ...state, displayLoadingModal: true }))
    const deleteWasSuccessful = await removeEmployee(caseId);
    if (deleteWasSuccessful) {
        let cases = [...state.cases];
        cases = cases.filter(item => item.caseId !== caseId);
        setState({ ...state, cases });
    } else {
        alert("Unable to delete employee, please try again in a moment. If the condition continues please contact support.")
    }
    setState(state => ({ ...state, displayLoadingModal: false }))
}

// add cases to table after new case form submit
interface AddCases {
    e: React.FormEvent<HTMLFormElement>;
    state: State;
    setState: React.Dispatch<React.SetStateAction<State>>;
    setDisplay: React.Dispatch<React.SetStateAction<Display>>;
}

export async function addCases({ e, state, setState, setDisplay }: AddCases) {
    e.preventDefault();
    setState(state => ({ ...state, displayLoadingModal: true }))

    const {
        reportId,
        cases,
        newCaseForm,
        newCaseForm: { employeeNumber, positiveTestDate },
        newCaseFormLocations,
        reportCreatedAt
    } = state;

    // if any new cases to add match any in the table, filter them out
    const casesToAdd = newCaseFormLocations.filter(
        item => {
            const date1 = new Date(positiveTestDate);
            const comparison = `${employeeNumber}-${item.location}-${date1.toDateString()}`;
            return !cases.some(({ employeeNumber, location, positiveTestDate }) => {
                const date2 = new Date(positiveTestDate);
                date2.setDate(date2.getDate() + 1);
                const row = `${employeeNumber}-${location}-${date2.toDateString()}`;
                return row === comparison;
            })
        }
    );

    const addedCases = await addRange({ newCaseFormLocations: casesToAdd, newCaseForm, reportId });

    if (addedCases) {
        const updatedCases = addedCases.map(item => (
            {
                reportID: item.reportId,
                caseId: item.employeeId,
                locationId: item.locationId,
                employeeNumber: item.employeeNumber,
                positiveTestDate: item.positiveDate.slice(0, 10),
                awarePositiveDate: item.awareDate.slice(0, 10),
                location: item.location,
                employeesOnSite: item.maximumNumberOnSitePrior.toString(),
                lastDayOnSite: item.lastOnsiteDate.slice(0, 10),
                reportDate: reportCreatedAt
            }
        ));

        setState(state => ({
            ...state,
            cases: [...state.cases, ...updatedCases],
            newCaseForm: { employeeNumber: "", positiveTestDate: "", awarePositiveDate: "" },
            newCaseFormLocations: []
        }));

        setDisplay(display => ({ ...display, showReportNewCasesLocations: false }));
    } else {
        alert("Unable to save cases, please try again in a moment. If the condition continues please contact support.");
    }
    setState(state => ({ ...state, displayLoadingModal: false }))
}

// edit new case form values
interface EditNewCaseForm {
    e: React.ChangeEvent<HTMLInputElement>;
    state: State;
    setState: React.Dispatch<React.SetStateAction<State>>;
}

export function editNewCaseForm({ e, state, setState }: EditNewCaseForm) {
    let value = e.target.value;

    if (typeof value === "string" && value.match(/\d{4}-\d{2}-\d{2}/)) {
        value = `${value.slice(5, 7)}/${value.slice(8, 10)}/${value.slice(0, 4)}`;
    }
    const stateCopy = { ...state };

    if (e.target.name in stateCopy.newCaseForm) {
        (stateCopy as Record<string, any>).newCaseForm[e.target.name] = value;
    }

    setState(stateCopy);
}

// add or remove (checkbox) new case form locations select
interface AddOrRemoveNewCaseFormLocations {
    e: React.ChangeEvent<HTMLInputElement>;
    locations: Location[];
    selectedLocations: NewCaseFormLocation[];
    setSelectedLocations: React.Dispatch<React.SetStateAction<NewCaseFormLocation[]>>;
}

export function addOrRemoveNewCaseFormLocations({ e, locations, selectedLocations, setSelectedLocations }: AddOrRemoveNewCaseFormLocations) {
    let selectedLocationsCopy = [...selectedLocations];

    if (e.target.checked) {
        const location = locations.find(item => item.locationId.toString() === e.target.id);
        if (location) {
            selectedLocationsCopy = [
                ...selectedLocations,
                {
                    locationId: location.locationId,
                    location: locationFormat(location),
                    employeesOnSite: "",
                    lastDayOnSite: ""
                }
            ];
        }
    } else if (!e.target.checked) {
        selectedLocationsCopy = selectedLocationsCopy.filter(item => !(item.locationId.toString() === e.target.id));
    }

    setSelectedLocations(selectedLocationsCopy);
}

// edit fields specific to locations selected
interface EditNewCaseFormLocations {
    e: React.ChangeEvent<HTMLInputElement>
    state: State;
    setState: React.Dispatch<React.SetStateAction<State>>;
}

export function editNewCaseFormLocations({ e, state, setState }: EditNewCaseFormLocations) {
    const { newCaseFormLocations } = state;
    let newCaseFormLocationsCopy = [...newCaseFormLocations];

    const index = newCaseFormLocationsCopy.findIndex(location => location.locationId.toString() === e.target.id);

    if (index !== -1 && e.target.name in newCaseFormLocationsCopy[index]) {
        (newCaseFormLocationsCopy as any)[index][e.target.name] = e.target.value;
        setState(state => ({ ...state, newCaseFormLocations: newCaseFormLocationsCopy }));
    }
}

// toggle select location form
interface ClickSelectLocationForm {
    setDisplay: React.Dispatch<React.SetStateAction<Display>>;
}

export function clickSelectLocationForm({ setDisplay }: ClickSelectLocationForm) {
    setDisplay(display => ({ ...display, showAllWorkLocations: !display.showAllWorkLocations }));
}

// clear all values in new location form
interface ClearForm {
    setState: React.Dispatch<React.SetStateAction<State>>;
    setDisplay: React.Dispatch<React.SetStateAction<Display>>;
}

export function clearForm({ setState, setDisplay }: ClearForm) {
    setState(state => ({ ...state, newCaseForm: { employeeNumber: "", positiveTestDate: "", awarePositiveDate: "" }, newCaseFormLocations: [] }));
    setDisplay(display => ({ ...display, showReportNewCasesLocations: false }));
}

// cancel dropdown and clear selected locations in dropdown
interface ClearLocationsDropdown {
    setSelectedLocations: React.Dispatch<React.SetStateAction<NewCaseFormLocation[]>>;
    setDisplay: React.Dispatch<React.SetStateAction<Display>>;
}

export function clearLocationsDropdown({ setSelectedLocations, setDisplay }: ClearLocationsDropdown) {
    setSelectedLocations([]);
    setDisplay(display => ({ ...display, showAllWorkLocations: false }));
}
