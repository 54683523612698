import React from 'react';
import { Button, Container, Modal } from 'react-bootstrap';

interface Props {
  children: JSX.Element;
  showLocationModal: boolean;
  handleClose: () => void;
  handleSave: (e: React.MouseEvent<HTMLFormElement>) => void;
}

export default function LocationModalContainer({ children, showLocationModal, handleClose, handleSave, }: Props) {
  return (
    <Modal show={showLocationModal} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
      <Container className="p-2 bg-white">
        <Modal.Header closeButton className="border-0">
          <Modal.Title>Add a New Location</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-white">
          {children}
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button variant="quaternary" onClick={handleClose} className="border rounded text-primary" style={{ width: "121px" }}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSave} className="border rounded" style={{ width: "121px" }}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Container>
    </Modal>
  )
}
