import { State } from "../../../Contexts/State/Types";
import { addReport, backToQuestionnaire } from "./Actions";
import { QuestionnaireForm } from "./Content/Main";

// Continue to the next page of the questionnaire
interface OnSubmitQuestionnaireQuestions {
  e: React.FormEvent<HTMLFormElement>
  form: QuestionnaireForm;
  setState: React.Dispatch<React.SetStateAction<State>>;
}

export function onSubmitQuestionnaireQuestions({ e, form, setState }: OnSubmitQuestionnaireQuestions) {
  e.preventDefault();

  const { isWorkRelated, isOnSite, isPositive, isOnSite14DaysPrior } = form;
  isWorkRelated === false && isOnSite && isPositive && isOnSite14DaysPrior && setState(state => ({ ...state, displayQuestionnaireForm: true }));
}

// Submit the entire form
interface OnSubmitQuestionnaireForm {
  e: React.FormEvent<HTMLFormElement>
  form: QuestionnaireForm;
  isValid: boolean;
  setHasSubmitted: React.Dispatch<React.SetStateAction<boolean>>;
  setState: React.Dispatch<React.SetStateAction<State>>;
}

export function onSubmitQuestionnaireForm({ e, form, isValid, setState, setHasSubmitted }: OnSubmitQuestionnaireForm) {
  e.preventDefault();
  setHasSubmitted(true);

  try {
    if (isValid) addReport({ form, setState });
  } catch (e) {
    console.error(e);
    alert("Unable to submit questionnaire, please try again in a moment. If the condition continues please contact support")
  }
}

// Event fired when clicking go back button on questionnaire form
interface OnClickGoBack {
  resetForm: () => void;
  setState: React.Dispatch<React.SetStateAction<State>>;
}

export function onClickGoBack({ resetForm, setState }: OnClickGoBack) {
  resetForm();
  backToQuestionnaire({ setState });
}


// Redirect when clicking yes to first quesiton
export function redirectToClaims(e: React.MouseEvent<HTMLElement>) {
  e.preventDefault();
  window.location.href = "https://bhhcpolicyholder.bhhc.com/Client/External/Claims";
}