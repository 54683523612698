import { Location, State } from "../Contexts/State/Types";
import { add, edit, getByPolicyNumber, remove } from "../Data/Location";
import { queryPreviousPolicyNumber } from "../Data/Report";
import { NewLocationForm } from "../Pages/Home/Report/Side/Main";

interface LoadingCall {
    isLoading: boolean;
    hasCalled: boolean;
}

interface GetLocationByPolicy {
    policyNumber: string;
    setLoadingCall: React.Dispatch<React.SetStateAction<LoadingCall>>;
    setState: React.Dispatch<React.SetStateAction<State>>;
}

export async function getLocationsByPolicy({ policyNumber, setLoadingCall, setState }: GetLocationByPolicy) {
    setLoadingCall({ isLoading: true, hasCalled: true });
    const locations = await getByPolicyNumber(policyNumber);

    if (locations && locations.some(({ locationId }) => locationId < 0)) {
        const previousPolicy = await queryPreviousPolicyNumber(policyNumber);
        if (previousPolicy && previousPolicy.trim() !== "NEW") {
            const comparableLocations = locations.map(({ address1, state, city, zipCode }) => address1 + state + city + zipCode);
            const oldLocations = await getByPolicyNumber(previousPolicy);
            oldLocations?.forEach(location => {
                if (location.locationId > 0) {
                    const toBeCompared = location.address1 + location.state + location.city + location.zipCode;
                    if (!comparableLocations.some(comparable => comparable === toBeCompared)) {
                        console.log("called");
                        locations.push(location);
                    }
                }
            })
        }
    }

    if (locations) {
        setState(state => ({
            ...state,
            locations: locations.map(location => ({ ...location }))
        }));
        setLoadingCall(loadingCall => ({ ...loadingCall, isLoading: false }));
    } else {
        alert("Unable to load locations, please try again in a moment. If the condition continues please contact support.");
    }
}

interface AddLocation {
    e: React.FormEvent<HTMLFormElement>;
    form: NewLocationForm;
    policyNumber: string;
    resetForm: () => void;
    setState: React.Dispatch<React.SetStateAction<State>>;
    setShowLocationModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export async function addLocation({ e, form, policyNumber, resetForm, setState, setShowLocationModal }: AddLocation) {
    e.preventDefault();

    setState(state => ({ ...state, displayLoadingModal: true }))
    const location = await add({ form, policyNumber });

    if (location) {
        resetForm();

        setShowLocationModal(false);

        setState(state => ({
            ...state,
            locations: [...state.locations, { ...location }],
        }));
    } else {
        alert("Unable to add location, please try again in a moment. If the condition continues please contact support.");
    }
    setState(state => ({ ...state, displayLoadingModal: false }))
}

interface EditLocation {
    e: React.FormEvent<HTMLFormElement>;
    form: NewLocationForm;
    locationId: number;
    locations: Location[];
    policyNumber: string;
    submitter: string;
    resetForm: () => void;
    setState: React.Dispatch<React.SetStateAction<State>>;
    setShowLocationModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export async function editLocation({ e, form, policyNumber, submitter, locations, locationId, resetForm, setState, setShowLocationModal }: EditLocation) {
    e.preventDefault();

    setState(state => ({ ...state, displayLoadingModal: true }))
    const dateEdited = new Date().toISOString();
    const success = await edit({ policyNumber, submitter, form, locationId, dateEdited });

    if (success) {
        const updatedLocation = {
            locationId,
            address1: form.address1,
            address2: form.address2,
            city: form.city,
            state: form.state,
            zipCode: form.zipCode,
            nickName: form.nickName
        } as Location

        resetForm();

        setState(state => ({
            ...state,
            locations: state.locations.map(location => location.locationId === locationId ? updatedLocation : location),
        }));

        setShowLocationModal(false);
    } else {
        alert("Unable to edit location, please try again in a moment. If the condition continues please contact support.");
    }
    setState(state => ({ ...state, displayLoadingModal: false }))
}

interface DeleteLocation {
    e: React.MouseEvent<HTMLElement, MouseEvent>;
    locationId: number;
    policyNumber: string;
    submitter: string;
    setState: React.Dispatch<React.SetStateAction<State>>;
}

export async function deleteLocation({ e, locationId, policyNumber, submitter, setState }: DeleteLocation) {
    e.preventDefault();
    setState(state => ({ ...state, displayLoadingModal: true }))
    const dateDeleted = new Date().toISOString();
    const success = await remove({ policyNumber, submitter, locationId, dateDeleted });

    if (success) {
        setState(state => ({
            ...state,
            locations: state.locations.filter(location => location.locationId !== locationId),
        }));
    } else {
        alert("Unable to delete location, please try again in a moment. If the condition continues please contact support.");
    }
    setState(state => ({ ...state, displayLoadingModal: false }))
}
