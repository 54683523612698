import { NewCaseForm, NewCaseFormLocation } from "../Contexts/State/Types";
import { EditCaseForm } from "../Pages/Home/Report/Content/Main";

interface AddRange {
    newCaseFormLocations: NewCaseFormLocation[];
    newCaseForm: NewCaseForm;
    reportId: number;
}

interface AddRangeResponse {
    awareDate: string;
    employeeId: number;
    employeeNumber: string;
    lastOnsiteDate: string;
    locationId: number;
    location: string;
    maximumNumberOnSitePrior: number;
    positiveDate: string;
    reportId: number;
    created: string;
}

export async function addRange({ newCaseFormLocations, newCaseForm, reportId }: AddRange): Promise<AddRangeResponse[] | null> {
    try {
        const body = JSON.stringify(
            newCaseFormLocations.map((location: NewCaseFormLocation) => {
                return {
                    ReportId: reportId,
                    EmployeeNumber: newCaseForm.employeeNumber,
                    AwareDate: newCaseForm.awarePositiveDate,
                    PositiveDate: newCaseForm.positiveTestDate,
                    Location: location.location,
                    LocationId: location.locationId,
                    LastOnsiteDate: location.lastDayOnSite,
                    MaximumNumberOnSitePrior: parseInt(location.employeesOnSite),
                }
            })
        );

        const res = await fetch("api/employee/addrange", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body
        });

        const data = await res.json();
        return data;
    } catch (e) {
        console.error(e);
    }

    return null;
}

export async function removeEmployee(id: number): Promise<boolean | null> {
    try {
        const res = await fetch(`api/employee/${id}`, {
            method: "DELETE",
            headers: {
                'Content-Type': 'application/json'
            },
        });

        const data = await res.json();
        return data;
    } catch (e) {
        console.error(e);
    }

    return null;
}

interface EditEmployee {
    form: EditCaseForm;
}

export async function updateEmployee({ form }: EditEmployee): Promise<boolean | null> {
    try {
        const body = JSON.stringify({
            ReportId: form.reportId,
            LocationId: form.locationId,
            employeeId: form.caseId,
            EmployeeNumber: form.employeeNumber,
            AwareDate: form.awarePositiveDate,
            PositiveDate: form.positiveTestDate,
            Location: form.location,
            LastOnsiteDate: form.lastDayOnSite,
            MaximumNumberOnSitePrior: parseInt(form.employeesOnSite),
        });

        const res = await fetch("api/employee/update", {
            method: "UPDATE",
            headers: {
                'Content-Type': 'application/json'
            },
            body
        });

        const data = await res.json();
        return data;
    } catch (e) {
        console.error(e);
    }

    return null;
}
