import { Location, NewCaseFormLocation } from "../Contexts/State/Types";

// Formats location based on location object
export function locationFormat(location: Location) {
    return `${location.address1}, ${location.address2 ? location.address2 + ', ' : ''}${location.city}, ${location.state} ${location.zipCode}`
}

// Formats firs and last name
export function fullnameFormat(firstName: string, lastName: string) {
    return firstName.trim() + " " + lastName?.trim();
}


// Date Utils

// Parses date and gets parameters from date (year, month, day)
interface GetDateParams {
    date: Date;
    offsetDays?: number;
}

function getDateParams({ date, offsetDays }: GetDateParams) {
    offsetDays && date.setDate(date.getDate() + offsetDays);
    return {
        yyyy: date.getFullYear(),
        mm: date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1,
        dd: date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
    }
}

// Format date from Date object
// If no date is provided, returns current date
interface FormatDate {
    date?: Date;
    separator?: string;
    offsetDays?: number;
}

export function dateToMMDDYYYY({ date, separator, offsetDays }: FormatDate) {
    const value = date ? new Date(date) : new Date();
    const { yyyy, mm, dd } = getDateParams({ date: value, offsetDays });
    return `${mm}${separator}${dd}${separator}${yyyy}`;
}

export function dateToYYYYMMDD({ date, separator, offsetDays }: FormatDate) {
    const value = date ? new Date(date) : new Date();
    const { yyyy, mm, dd } = getDateParams({ date: value, offsetDays });
    return `${yyyy}${separator}${mm}${separator}${dd}`;
}

// Format date from string (such as 2020-10-12)
// If no date is provided, returns current date
interface FormatDateFromString {
    date?: string;
    separator?: string;
    offsetDays?: number;
}

export function stringToMMDDYYYY({ date, separator, offsetDays }: FormatDateFromString) {
    const parsedDateString = date ? new Date(date.replace(/-/g, "/")) : new Date();
    return dateToMMDDYYYY({ date: parsedDateString, separator, offsetDays });
}

export function stringToYYYYMMDD({ date, separator, offsetDays }: FormatDateFromString) {
    const parsedDateString = date ? new Date(date.replace(/-/g, "/")) : new Date();
    return dateToYYYYMMDD({ date: new Date(parsedDateString), separator, offsetDays });
}

// Compares dates for shorter syntax inline
export function compareDates(date1: Date, comparison: "<=" | "<" | "=" | ">" | ">=", date2: Date) {
    switch (comparison) {
        case "<=":
            return date1 <= date2;
        case "<":
            return date1 < date2;
        case "=":
            return date1 === date2;
        case ">":
            return date1 > date2;
        case ">=":
            return date1 >= date2;
        default:
            return false;
    }
}

export function compareDateStrings(date1: string, comparison: "<=" | "<" | "=" | ">" | ">=", date2: string) {
    const parsedDate1 = new Date(date1.replace(/-/g, "/"));
    const parsedDate2 = new Date(date2.replace(/-/g, "/"));

    switch (comparison) {
        case "<=":
            return parsedDate1 <= parsedDate2;
        case "<":
            return parsedDate1 < parsedDate2;
        case "=":
            return parsedDate1 === parsedDate2;
        case ">":
            return parsedDate1 > parsedDate2;
        case ">=":
            return parsedDate1 >= parsedDate2;
        default:
            return false;
    }
}

// Sorting of Locations
export const sortLocations = (data: Location[]): Location[] => {
    return data.sort(function (a, b) {
        return compareLocations(a, b);
    });
};

const compareLocations = (a: Location, b: Location): number => {
    let aString = `${a.address1} ${a.city} ${a.zipCode} ?? ''}`.toLowerCase();
    let bString = `${b.address1} ${b.city} ${b.zipCode ?? ''}`.toLowerCase();
    return aString < bString ? -1 :
        aString > bString ? 1 :
            0;
}

export const sortNewCaseFormLocations = (data: NewCaseFormLocation[]): NewCaseFormLocation[] => {
    return data.sort(function (a, b) {
        return compareNewCaseFormLocations(a, b);
    });
};

const compareNewCaseFormLocations = (a: NewCaseFormLocation, b: NewCaseFormLocation): number => {
    let aString = `${a.location}`.toLowerCase();
    let bString = `${b.location}`.toLowerCase();
    return aString < bString ? -1 :
        aString > bString ? 1 :
            0;
}

export const getLocationByID = (id: number, locations: Location[]) => {
    const location = locations.find(location => location.locationId === id);
    return location ?? null;
}