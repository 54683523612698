import React from 'react';

import Content from './Content';

export default function Contact() {
    return (
        <div className="row justify-content-md-end">
            <div className="row col-md-10">
                <div className="col-md-5 pr-md-5">
                    <Content />
                </div>
                <div className="col-md-7 pl-md-5">
                </div>
            </div>
        </div>
    )
}
