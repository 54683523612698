import React, { useState } from 'react';
import { deleteEmployee, editEmployee } from '../../../../Actions/Employee';
import { useSetStateContext, useStateContext } from '../../../../Contexts/State/Context';
import { Case } from '../../../../Contexts/State/Types';
import { useForm } from '../../../../Hooks/UseForm';
import { validateEditCaseForm } from '../Validations';
import CasesTable from "./CasesTable";
import EditCaseModalContainer from './EditCaseModalContainer';
import EditCaseModalForm from './EditCaseModalForm';
import NewCaseFormContainer from './NewCaseFormContainer';

const initialData = {
  reportId: 0,
  locationId: 0,
  caseId: 0,
  employeeNumber: "",
  positiveTestDate: "",
  awarePositiveDate: "",
  lastDayOnSite: "",
  location: "",
  employeesOnSite: "",
}

export type EditCaseForm = typeof initialData;

export default function ReportContent(): JSX.Element {
  // Context
  const state = useStateContext();
  const { reportId, locations, cases } = state;
  const setState = useSetStateContext();

  // State
  const [showEditCaseModal, setShowEditCaseModal] = useState(false);
  const { form, resetForm, onFormEvent, setForm, hasSubmitted, setHasSubmitted } = useForm<EditCaseForm>({ initialData });
  const { isValid, fieldErrors } = validateEditCaseForm({ form });

  // Events
  const handleOpen = (caseItem: Case) => {
    setForm(
      {
        reportId,
        locationId: caseItem.locationId,
        caseId: caseItem.caseId,
        employeeNumber: caseItem.employeeNumber,
        location: caseItem.location,
        employeesOnSite: caseItem.employeesOnSite,
        awarePositiveDate: caseItem.awarePositiveDate,
        lastDayOnSite: caseItem.lastDayOnSite,
        positiveTestDate: caseItem.positiveTestDate,
      });
    setShowEditCaseModal(true);
  }

  const handleClose = () => {
    setShowEditCaseModal(false);
    resetForm();
  }

  const handleSave = (e: React.FormEvent<HTMLFormElement>) => {
    !hasSubmitted && setHasSubmitted(true);
    if (isValid) {
      editEmployee({ e, form, setState });
      setShowEditCaseModal(false);
    }
  };

  const handleDelete = (caseId: number) => deleteEmployee({ caseId, state, setState });

  return (
    <>
      <NewCaseFormContainer />
      {cases.length > 0 && <CasesTable setForm={setForm} handleOpen={handleOpen} handleDelete={handleDelete} />}
      <EditCaseModalContainer showEditCaseModal={showEditCaseModal} isValid={isValid} handleClose={handleClose} handleSave={handleSave}>
        <EditCaseModalForm form={form} locations={locations} fieldErrors={fieldErrors} hasSubmitted={hasSubmitted} onFormEvent={onFormEvent} />
      </EditCaseModalContainer>
    </>
  )
}
