import React, { useEffect, useState } from 'react';
import { Button, Container, Row } from 'react-bootstrap';
import { addLocation, deleteLocation, editLocation } from '../../../../Actions/Location';
import { useSetStateContext, useStateContext } from '../../../../Contexts/State/Context';
import { useForm } from '../../../../Hooks/UseForm';
import { getLocationByID } from '../../../../Utils/Utils';
import MessageToolTips from '../../MessageToolTips';
import { validateNewLocationForm } from '../Validations';
import LocationDisplay from "./LocationDisplay";
import LocationModalContainer from './LocationModalContainer';
import LocationModalForm from './LocationModalForm';

const initialData = {
    address1: "",
    address2: "",
    city: "",
    state: "",
    zipCode: "",
    nickName: "",
};

export type NewLocationForm = typeof initialData;

export default function ReportSide() {
    // Context
    const { policyNumber, submitter, locations } = useStateContext();
    const setState = useSetStateContext();

    // State
    const [showLocationModal, setShowLocationModal] = useState(false);
    // The location ID of the location being copied or edited
    const [modalLocationID, setModalLocationID] = useState(Number.NEGATIVE_INFINITY);
    const { form, setForm, onFormEvent, resetForm, hasSubmitted, setHasSubmitted } = useForm<NewLocationForm>({ initialData });
    const { isValid, fieldErrors } = validateNewLocationForm({ form });

    // Events
    const handleOpen = () => {
        resetForm();
        setShowLocationModal(true);
    }

    const handleOpenEditOrCopy = (id: number) => {
        resetForm();
        setModalLocationID(id);
        setShowLocationModal(true);
    }

    const handleClose = () => {
        setShowLocationModal(false);
        setModalLocationID(Number.NEGATIVE_INFINITY);
    }

    const handleSave = (e: React.MouseEvent<HTMLFormElement>) => {
        !hasSubmitted && setHasSubmitted(true);
        if (!isValid) return;

        setShowLocationModal(false);

        modalLocationID > 0
            ?
            editLocation({ e, form, policyNumber, submitter, locations, locationId: modalLocationID, resetForm, setState, setShowLocationModal })
            :
            addLocation({ e, form, policyNumber, resetForm, setState, setShowLocationModal });

        setModalLocationID(Number.NEGATIVE_INFINITY);
    }

    const handleDelete = (e: React.MouseEvent<HTMLElement, MouseEvent>, locationId: number) => {
        deleteLocation({ e, locationId, policyNumber, submitter, setState });
    }

    // Effects
    useEffect(() => {
        if (modalLocationID === Number.NEGATIVE_INFINITY) {
            setForm(initialData)
        } else {
            const location = getLocationByID(modalLocationID, locations);
            if (location === null) return;
            setForm({
                address1: location.address1,
                address2: location.address2,
                city: location.city,
                state: location.state,
                zipCode: location.zipCode,
                nickName: location.nickName,
            })
        }
    }, [modalLocationID, locations, policyNumber, setForm]);


    return (
        <>
            <Container fluid className="bg-attention rounded p-3 mt-4 mt-lg-0">
                <Row className="row justify-content-between pb-2">
                    <h5 className="font-weight-bold">Add New Location</h5>
                    <MessageToolTips prompt='Locations' helpText='What is this?' />
                    <Button variant="attention" onClick={handleOpen}>+ Add New</Button>
                </Row>
                <LocationDisplay handleOpenEditOrCopy={handleOpenEditOrCopy} handleDelete={handleDelete} />
            </Container>
            <Container fluid className="mb-5 mt-2">
                <p>*Adding location(s) to the Covid Tracker will NOT add locations to your policy coverage. If you need assistance updating locations in your policy, please contact your broker or us at 800-661-6029 or <a href="mailto:customercare@bhhc.com">customercare@bhhc.com</a>.</p>
            </Container>
            <LocationModalContainer showLocationModal={showLocationModal} handleClose={handleClose} handleSave={handleSave}>
                <LocationModalForm form={form} fieldErrors={fieldErrors} hasSubmitted={hasSubmitted} onFormEvent={onFormEvent} />
            </LocationModalContainer>
        </>
    );
}

