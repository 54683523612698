import React from 'react';
import { maxContainerWidth } from '../../Contexts/State/Constants';
import { useStateContext } from '../../Contexts/State/Context';
import logo from "../../Static/logo.png";


export default function Header() {
    const { insuredName, policyNumber } = useStateContext();

    const willDisplay = insuredName && policyNumber;

    return (
        <header className="row justify-content-between align-items-center bg-light py-4 px-4" style={{ minHeight: "10vh" }}>
            <div className="row col-12 mx-auto" style={{ maxWidth: maxContainerWidth }}>
                <div className="row col-xs-12 col-md-6">
                    <div className="col-auto p-0 pr-2">
                        <img src={logo} alt="BHHC Logo" style={{ width: "44px", height: "auto" }} />
                    </div>
                    <div className="col-auto p-0">
                        <h4 className="p-0 m-0 text-primary">COVID TRACKER</h4>
                        <p className="p-0 m-0 text-tertiary" style={{ fontSize: "12px" }}>Workers Compensation</p>
                    </div>
                </div>
                {willDisplay &&
                    <div className="col-xs-12 mt-4 col-md-6 mt-md-0">
                        <div className="row justify-content-start justify-content-md-end">
                            <p className="font-weight-bold mr-3">Insured Name:</p>
                            <p>{insuredName}</p>
                        </div>
                        <div className="row justify-content-start justify-content-md-end">
                            <p className="font-weight-bold mr-3">Policy Number:</p>
                            <p>{policyNumber}</p>
                        </div>
                    </div>
                }
            </div>
        </header>
    )
}
