import React from "react";
import { Form } from "react-bootstrap";

export function RedAsterisk() {
    return <span className="text-danger"> *</span>
}

interface FieldValidationMessageProps {
    display: boolean;
    message: string;
    type: "danger" | "warning" | "success";
}

export function FieldValidationMessage({ display, message, type }: FieldValidationMessageProps) {
    return display ?
        <Form.Text className={`form-text text-${type}`}>{message}</Form.Text> :
        null;
}
