import React from 'react';
import { useStateContext } from '../../Contexts/State/Context';
import Footer from './Footer';
import Header from './Header';
import Loading from './Loading';
import Navbar from './Navbar';
import ReportFooter from './ReportFooter';


interface Props {
    children: JSX.Element[]
}

export default function Layout({ children }: Props) {

    return (
        <>
            <Header />
            <div style={{ border: "20px solid white" }} >
                <p>As of 1/01/24 California policyholders are no longer required to report and/or track employees who test positive for COVID 19.  <br />If you have questions regarding claim reporting, please contact Customer Care at 800-695-8949. Thank you.</p>
            </div>

        </>
    );
}
