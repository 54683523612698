import React, { useEffect, useState } from 'react';
import { useSetStateContext } from '../../../../Contexts/State/Context';
import { applyPhoneMask } from '../../../../Hooks/UseForm';
import { FieldValidationMessage, RedAsterisk } from '../../../../Utils/Components';
import MessageToolTips from '../../MessageToolTips';
import { validatePolicyGetInsured } from '../Actions';
import { onClickGoBack } from '../Events';
import { Validations } from '../Validations';
import { QuestionnaireForm } from './Main';


interface Props {
    form: QuestionnaireForm;
    fieldErrors: Validations;
    onFormEvent: (e: React.ChangeEvent<HTMLInputElement>) => void;
    resetForm: () => void;
    hasSubmitted: boolean;
}

export default function ReportingForm({ form, onFormEvent, resetForm, fieldErrors, hasSubmitted }: Props) {
    // context
    const setState = useSetStateContext();

    // state
    const [policyExists, setPolicyExists] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        !fieldErrors.policyNumber
            &&
            (async () => {
                setIsLoading(true);
                await validatePolicyGetInsured({
                    setPolicyExists,
                    setState,
                    policyNumber: form.policyNumber
                })
                setIsLoading(false);
            })();
    }, [form.policyNumber, fieldErrors.policyNumber, setState])

    return (
        <>
            <div className="row col-12 pb-3 justify-content-between">
                <h5 className="font-weight-bold">Policy Information</h5>
                <span className="text-danger">* indicates required field</span>
            </div>
            <div className="form-group col-12 mb-2">
                <label htmlFor="policyNumber">Policy Number<RedAsterisk />
                    <MessageToolTips prompt='Policy Number' helpText='How to find my policy number?' />
                </label>
                <input type="text" className="form-control p-1" id="policyNumber" name="policyNumber" placeholder="e.g.: ACWC123456" onChange={onFormEvent} />
                {hasSubmitted && form.policyNumber === ""
                    ?
                    <FieldValidationMessage display={true} message="Field cannot be empty" type="danger" />
                    :
                    isLoading
                        ?
                        <div className="fa-1x text-primary row align-items-center mt-1">
                            <i className="fas fa-spinner fa-spin text-primary mr-2"></i>
                            <small>Please wait while we verify if policy is valid</small>
                        </div>
                        :
                        fieldErrors.policyNumber
                            ?
                            <FieldValidationMessage display={form.policyNumber !== ""} message="policy number must be in format ACWC123456" type="danger" />
                            :
                            policyExists
                                ?
                                <FieldValidationMessage display={true} message="You can report a case for this policy number" type="success" />
                                :
                                <FieldValidationMessage display={true} message="You can not report a case for this policy number" type="danger" />

                }
            </div>
            <div className="col-12 d-flex align-items-center pt-4 pb-2">
                <h5 className="font-weight-bold">Submitter Information</h5>
                <MessageToolTips prompt='Submitter' helpText='Why do we need this?' />
            </div>
            <div className="form-group col-md-6 pr-md-2">
                <label htmlFor="firstName">First Name<RedAsterisk /></label>
                <MessageToolTips prompt='First name' helpText='' />
                <input type="text" className="form-control p-1" id="firstName" name="firstName" onChange={onFormEvent} />
                <FieldValidationMessage display={hasSubmitted && fieldErrors.firstName} message="Field must have 1 to 100 characters" type="danger" />
            </div>
            <div className="form-group col-md-6 pl-md-2">
                <label htmlFor="lastName">Last Name<RedAsterisk /></label>
                <MessageToolTips prompt='Last name' helpText='' />
                <input type="text" className="form-control p-1" id="lastName" name="lastName" onChange={onFormEvent} />
                <FieldValidationMessage display={hasSubmitted && fieldErrors.lastName} message="Field must have 1 to 100 characters" type="danger" />
            </div>
            <div className="form-group col-12 pt-2">
                <label htmlFor="email">Email<RedAsterisk /></label>
                <MessageToolTips prompt='Email' helpText='' />
                <input type="email" className="form-control p-1" id="email" name="email" onChange={onFormEvent} />
                <FieldValidationMessage display={hasSubmitted && fieldErrors.email} message="Field must have 1 to 256 characters" type="danger" />
            </div>
            <div className="form-group col-12 pt-2">
                <label htmlFor="phone">Phone<RedAsterisk /></label>
                <MessageToolTips prompt='Phone' helpText='' />
                <input type="tel" className="form-control p-1" id="phone" name="phone" onChange={onFormEvent} value={applyPhoneMask({ value: form.phone ?? "" })} style={{ caretColor: "transparent" }} />
                <FieldValidationMessage display={hasSubmitted && fieldErrors.phone} message="Field must have full phone number" type="danger" />
            </div>
            <div className="row col-12 pt-3">
                <button type="button" className="btn btn-secondary mt-2 mb-5 mr-2" onClick={() => onClickGoBack({ resetForm, setState })} style={{ width: "120px" }}>Go Back</button>
                <button type="submit" className="btn btn-primary mt-2 mb-5" style={{ width: "120px" }}>Submit Case(s)</button>
            </div>
        </>
    );
}
