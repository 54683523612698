import React from 'react';
import { OverlayTrigger, Popover, Row } from 'react-bootstrap';

import { useStateContext } from '../../Contexts/State/Context';

interface Props {
    prompt: string;
    helpText: string;
}

export default function MessageToolTips({ prompt, helpText }: Props) {
    const { messages } = useStateContext();

    // Gets the help text from the messages collection
    // defaults to an empty string
    function getHelpText(prompt: string) {
        if (!messages) return null;

        const item = messages.find(p => p.prompt === prompt);
        return item ? item.toolTip : null;
    }

    const message = getHelpText(prompt);

    const popover = (
        <Popover id={`tooltip-${prompt}`}>
            <Popover.Content className="p-2 text-primary">
                {message}
            </Popover.Content>
        </Popover>
    );

    return message ?
        <OverlayTrigger placement="bottom" overlay={popover}>
            <Row className="ml-2 d-inline-block align-items-center">
                <i className="fa fa-question-circle fa-xs text-primary"></i>
                <span className="text-primary ml-1" style={{ fontSize: "12px" }}>{helpText}</span>
            </Row >
        </OverlayTrigger>
        : null;
}
