import React from 'react';

export default function ContactContent() {

    return (
        <div className="mt-2">
            <h1>Contact Us</h1>
            <div className="row m-3">
                <div className="row col-md-8">
                    <div className="col pr-md-2 font-weight-bold">
                        <u>Customer Care Center</u>
                    </div>
                </div>
                <div className="row col-md-8">
                    <div className="col pr-md-2">
                        1-800-661-6029
                    </div>
                </div>
                <div className="row col-md-8">
                    <div className="col pr-md-2">
                        M-F 6:00 AM - 6:00 PM PST
                    </div>
                </div>
                <div className="row col-md-8">
                    <div className="col pr-md-2">
                        <a href="mailto:customercare@bhhc.com">customercare@bhhc.com</a>
                    </div>
                </div>
            </div>
            <div className="row m-3">
                <div className="row col-md-8">
                    <div className="col pr-md-2 font-weight-bold">
                        <u>Report a Claim</u>
                    </div>
                </div>
                <div className="row col-md-8">
                    <div className="col pr-md-2">
                        Online: <a href="https://www.bhhc.com">bhhc.com</a>
                    </div>
                </div>
                <div className="row col-md-8">
                    <div className="col pr-md-2">
                        Email: <a href="mailto:newclaim@bhhc.com">newclaim@bhhc.com</a>
                    </div>
                </div>
                <div className="row col-md-8">
                    <div className="col pr-md-2">
                        Phone: 1-800-661-6029
                    </div>
                </div>
            </div>
        </div>
    );
}
