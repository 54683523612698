import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { addCases, clearForm } from '../../../../Actions/Employee';
import { useSetStateContext, useStateContext } from '../../../../Contexts/State/Context';
import { NewCaseFormLocation } from '../../../../Contexts/State/Types';
import { validateNewCaseForm, validateNewCaseFormLocations } from '../Validations';
import NewCaseForm from './NewCaseForm';
import NewCaseFormLocations from './NewCaseFormLocations';

const initialData = {
    showReportNewCase: true,
    showAllWorkLocations: false,
    showReportNewCasesLocations: false,
}

export type Display = typeof initialData;

export default function NewCaseFormContainer() {
    // Context
    const state = useStateContext();
    const setState = useSetStateContext();

    // State
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [display, setDisplay] = useState(initialData);
    const [selectedLocations, setSelectedLocations] = useState<NewCaseFormLocation[]>([]);
    const { isValid, fieldErrors, fieldWarnings } = validateNewCaseForm({ state });
    const { isValid: isValidLocations, validations } = validateNewCaseFormLocations({ state });

    // Events
    const handleClearForm = () => {
        setHasSubmitted(false);
        clearForm({ setState, setDisplay });
    }

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setHasSubmitted(true);
        if (isValid && isValidLocations) {
            addCases({ e, state, setState, setDisplay });
            setHasSubmitted(false);
        }
    };

    return (
        <Form id="add-cases-form" className="border rounded py-3" onSubmit={onSubmit}>
            <Row className="align-items-center mx-2 mb-2" style={{ cursor: "pointer" }} onClick={() => setDisplay(display => ({ ...display, showReportNewCase: !display.showReportNewCase }))}>
                <div className="col row align-items-center">
                    <i className="fas fa-caret-down mr-2"></i>
                    <h5 className="font-weight-bold">Report a New Case</h5>
                </div>
                <div className="col-auto">
                    <span className="text-danger pr-3">* indicates required field</span>
                </div>
            </Row>
            <Row className="px-4">
                <NewCaseForm
                    fieldErrors={fieldErrors}
                    fieldWarnings={fieldWarnings}
                    hasSubmitted={hasSubmitted}
                    setHasSubmitted={setHasSubmitted}
                    display={display}
                    setDisplay={setDisplay}
                    selectedLocations={selectedLocations}
                    setSelectedLocations={setSelectedLocations} />
                <NewCaseFormLocations
                    validations={validations}
                    hasSubmitted={hasSubmitted}
                    setHasSubmitted={setHasSubmitted} />
                {!display.showAllWorkLocations &&
                    <Form.Group as={Row} className="mt-4 w-100">
                        <Col xs={6}>
                            <Button type="button" variant="secondary" className="mr-2 w-100" onClick={handleClearForm} >Clear</Button>
                        </Col>
                        <Col xs={6}>
                            <Button type="submit" variant="primary" className="ml-2 w-100" >Save Cases</Button>
                        </Col>
                    </Form.Group>
                }
            </Row>
        </Form>
    )
}
