import React from 'react';

export default function ThankYou() {
    return (
        <div className="row justify-content-center">
            <div className="row col-10 justify-content-center">
                <h1 className="col-12 text-primary my-5">Thank You For Your Submission</h1>
                <i className="fas fa-check-circle text-success col-12 fa-5x mb-5"></i>
                <h4 className="col-12 my-1 text-tertiary">We have received your COVID-19 Tracking Report.</h4>
                <h4 className="col-12 mt-1 mb-5 text-tertiary">Thank you very much for providing the information.</h4>
                <p className="col-12 pb-4">
                    Please remember that if you have an employee who tests positive and
                    reports they contracted COVID-19 at work, you need to provide them a
                    DWC-1 Claim Form within 1 business day and report to BHHC through &nbsp;
                    <a href="https://bhhcpolicyholder.bhhc.com/Client/External/Claims">our online reporting tool.</a>
                </p>
                <p className="col-12">
                    You may also call (800) 661-6029, email &nbsp;
                    <a href="mailto:newclaim@bhhc.com">newclaim@bhhc.com</a>&nbsp;
                    or fax (800) 661-6984.
                </p>
            </div>
        </div>
    );
}
