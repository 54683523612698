import React from 'react';

interface Props {
    index: number;
    text: string;
}

export function InstructionVertical({ index, text }: Props) {
    return (
        <div className="col">
            <div className="row justify-content-center">
                <div className="my-3 step-indicator rounded-circle bg-info row align-items-center justify-content-center" style={{ width: "32px", height: "32px" }}>
                    <span className="align-middle text-light">{index + 1}</span>
                </div>
                <span className="text-center" style={{ fontSize: "14px" }}>
                    {text}
                </span>
            </div>
        </div>
    )
}

export function InstructionHorizontal({ index, text }: Props) {
    return (
        <div className="row flex-nowrap align-items-center my-3">
            <div className="col-auto mr-4 step-indicator rounded-circle bg-info row align-items-center justify-content-center" style={{ width: "32px", height: "32px" }}>
                <span className="align-middle text-light">{index + 1}</span>
            </div>
            <span>
                {text}
            </span>
        </div>
    )
}